export const USERS_LOGIN_USER_SUCCESS = "users/LOGIN_USER_SUCCESS";
export const USERS_GOOGLE_LOGIN_USER_SUCCESS =
  "users/GOOGLE_LOGIN_USER_SUCCESS";
export const USERS_LOGIN_USER_FAILED = "users/LOGIN_USER_FAILED";
export const USERS_LOGIN_USER_SET_FIELD = "users/LOGIN_USER_SET_FIELD";
export const USERS_LOGOUT_USER_SUCCESS = "users/USERS_LOGOUT_USER_SUCCESS";
export const USERS_AUTO_LOGIN_USER = "users/LOGIN_USER_AFTER_SIGNUP";

export const USERS_SIGNUP_USER_SUCCESS = "users/SIGNUP_USER_SUCCESS";
export const USERS_AUTO_CREATE_USER_SUCCESS = "users/AUTO_CREATE_USER_SUCCESS";
export const USERS_SIGNUP_EMAIL_CHECK_SUCCESS =
  "users/SIGNUP_EMAIL_CHECK_SUCCESS";
export const USERS_SIGNUP_EMAIL_CHECK_FAILED =
  "users/SIGNUP_EMAIL_CHECK_FAILED";
export const USERS_USER_SEND_SUPPORT_INQUERY_SUCCESS =
  "users/USER_SEND_SUPPORT_INQUERY_SUCCESS";
export const USERS_ADD_USER_TRACKING_SUCCESS =
  "users/USERS_ADD_USER_TRACKING_SUCCESS";
