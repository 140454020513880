import React from "react";

const RatingStarIcon = ({ className, height = 25, width = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.8287 1.5833L15.229 8.79519L15.3429 9.13729H15.7034H23.437L17.1908 13.5676L16.8887 13.7819L17.0057 14.1333L19.3979 21.321L13.1179 16.8667L12.8287 16.6616L12.5394 16.8667L6.2594 21.321L8.65167 14.1333L8.76864 13.7819L8.46652 13.5676L2.2203 9.13729H9.95394H10.3145L10.4284 8.79519L12.8287 1.5833Z"
      fill="#FFDD29"
      stroke="black"
    />
  </svg>
);

export default RatingStarIcon;
