import uniq from "lodash/uniq";

export const join = ({ strings, delimiter = "," }) =>
  uniq(strings.filter((s) => !!s && typeof s !== "object")).join(delimiter);
export const toClassNames = (classNameArray) =>
  join({ strings: classNameArray, delimiter: " " });

export const convertToProductCategoryUrl = ({ category, subCategory }) => {
  return `/products?topCategory=${category
    .replaceAll(" ", "+")
    .replaceAll("&", "%26")}&categories=${subCategory
    .replaceAll(" ", "+")
    .replaceAll("&", "%26")}`;
};
