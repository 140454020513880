import React from "react";
import "./categoryBanner.scss";

const CategoryBanner = ({ categories }) => {
  return (
    <div className="product-group-category-banner-container">
      <div className="product-group-category-banner-background-image">
        <div className="product-group-category-banner">
          <div className="product-group-category-banner-title">
            Home & Kitchen
          </div>
          <div className="product-group-category-banner-subTitle">
            All essentials for modern living and culinary delight
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBanner;
