import React from "react";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { Link } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import homeKitchenImage from "../../images/category-home-kitchen.png";
import kidsToysImage from "../../images/category-kid-toy.png";
import fashionBeautyImage from "../../images/category-fashion-beauty.png";
import healthSportsImage from "../../images/category-health-sports.png";
import techGadgetsImage from "../../images/categories-tech-gadgets.png";

import "./shopByCategories.scss";
import { useTranslation } from "react-i18next";

const ShopByCategories = () => {
  const { t } = useTranslation();

  return (
    <div className="main-page-shop-by-category-container">
      <div className="col-home-kitchen-kids-toys-fashion-sports">
        <div className="row-home-kitchen-kids-toys category-box">
          <Link
            className="col-home-kitchen category-link"
            to="/products?topCategory=Home+%26+Kitchen&categories=Living+%26+Dining,Garden+%26+Outdoor,Home+Improvement,Appliances,Office"
          >
            <div className="col-home-kitchen-image">
              <AsyncImage
                src={homeKitchenImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                }}
                Transition={Blur}
                loader={<div style={{ background: "#888" }} />}
              />
            </div>
            <div className="col-home-kitchen-text-button">
              <div className="col-home-kitchen-text">
                {t("product_category_home_kitchen")}
              </div>
              <div className="col-home-kitchen-button-container">
                <IoMdArrowForward size={24} className="home-kitchen-button" />
              </div>
            </div>
          </Link>
          <Link
            className="col-kids-and-toys category-link"
            to="/products?topCategory=Kids+%26+Pets&categories=Toys+%26+Games,Pet+Supplies,Baby"
          >
            <div className="col-kids-and-toys-image">
              <AsyncImage
                src={kidsToysImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                }}
                Transition={Blur}
                loader={<div style={{ background: "#888" }} />}
              />
            </div>
            <div className="col-kids-and-toys-text-button">
              <div className="col-kids-and-toys-text">
                {t("product_category_kids_pets")}
              </div>
              <div className="col-kids-and-toys-button-container">
                <IoMdArrowForward size={24} className="kids-and-toys-button" />
              </div>
            </div>
          </Link>
        </div>
        <div className="row-fashion-beauty-health-sports category-box">
          <Link
            className="col-fashion-beauty category-link"
            to={
              "/products?topCategory=Fashion+%26+Beauty&categories=Beauty,Clothing,Fashion,Handmade,Arts%2C+Crafts+%26+Sewing"
            }
          >
            <div className="col-fashion-beauty-image">
              <AsyncImage
                src={fashionBeautyImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                }}
                Transition={Blur}
                loader={<div style={{ background: "#888" }} />}
              />
            </div>
            <div className="col-fashion-beauty-text-button">
              <div className="col-fashion-beauty-text">
                {t("product_category_fashion_beauty")}
              </div>
              <div className="col-fashion-beauty-button-container">
                <IoMdArrowForward size={24} className="fashion-beauty-button" />
              </div>
            </div>
          </Link>
          <Link
            className="col-health-sports category-link"
            to={
              "/products?topCategory=Health+%26+Sport&categories=Sports+%26+Outdoors,Health+%26+Household,Grocery+%26+Gourmet+Food"
            }
          >
            <div className="col-health-sports-image">
              <AsyncImage
                src={healthSportsImage}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 0,
                }}
                Transition={Blur}
                loader={<div style={{ background: "#888" }} />}
              />
            </div>
            <div className="col-health-sports-text-button">
              <div className="col-health-sports-text">
                {t("product_category_health_sports")}
              </div>
              <div className="col-health-sports-button-container">
                <IoMdArrowForward size={24} className="health-sports-button" />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <Link
        className="col-tech-gadgets category-link category-box"
        to={
          "/products?topCategory=Tech+%26+Gadgets&categories=Electronics,Cell+Phones+%26+Accessories,Video+Games,Automotive,Industrial+%26+Scientific,Musical+Instruments"
        }
      >
        <div className="col-tech-gadgets-image">
          <AsyncImage
            src={techGadgetsImage}
            style={{
              width: "328px",
              height: "573px",
              borderRadius: 0,
            }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
        </div>
        <div className="col-tech-gadgets-text-button">
          <div className="col-tech-gadgets-text">
            {t("product_category_tech_gadgets")}
          </div>
          <div className="col-tech-gadgets-button-container">
            <IoMdArrowForward size={24} className="tech-gadgets-button" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ShopByCategories;
