import React from "react";
import Modal from "./Modal";
import { CloseSign, Message } from "./ModalPopup";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";

const BaseModalWrapper = ({
  children,
  onClose,
  ContainerComponent,
  CloseButtonComponent,
  logoImage,
  HeaderComponent,
  header,
  message,
}) => {
  const handleCloseButtonClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Modal onClick={onClose}>
      <ContainerComponent>
        <CloseButtonComponent onClick={handleCloseButtonClick}>
          <CloseSign />
        </CloseButtonComponent>
        {logoImage && (
          <AsyncImage
            src={logoImage}
            style={{
              width: 100,
              height: 100,
              borderRadius: 15,
            }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
        )}
        <HeaderComponent>{header}</HeaderComponent>
        {message ? <Message>{message}</Message> : null}
        {children}
      </ContainerComponent>
    </Modal>
  );
};

export default BaseModalWrapper;
