import { Options } from "./Options";

const ar = {
  title: "languages_ar",
  code: "ar-AR",
  countryCode: "SA",
};

const en = {
  title: "languages_en",
  code: "en-US",
  countryCode: "US",
};

const es = {
  title: "languages_es",
  code: "es-MX",
  countryCode: "MX",
};

const fil = {
  title: "languages_fil",
  code: "fil-PH",
  countryCode: "PH",
};

const fr = {
  title: "languages_fr",
  code: "fr-FR",
  countryCode: "FR",
};

const ko = {
  title: "languages_ko",
  code: "ko-KR",
  countryCode: "KR",
};

const ja = {
  title: "languages_ja",
  code: "ja-JP",
  countryCode: "JP",
};

const ru = {
  title: "languages_ru",
  code: "ru-RU",
  countryCode: "RU",
};

const vi = {
  title: "languages_vi",
  code: "vi-VI",
  countryCode: "VN",
};

const zh = {
  title: "languages_zh",
  code: "zh-CN",
  countryCode: "CN",
};

const languages = {
  SA: ar,
  US: en,
  MX: es,
  PH: fil,
  FR: fr,
  KR: ko,
  JP: ja,
  RU: ru,
  VN: vi,
  CN: zh,
};

export const languaguesOptions = new Options(languages);
