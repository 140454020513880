import React from "react";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";
import ProductDetailPage from "../redesign/ProductDetailPage";
import DirectoryNavBar from "../header/DirectoryNavBar";
import { Footer } from "../footer/Footer";
import { useIsMobile } from "./hook/layoutHooks";
import { MobileProductPage } from "./mobile/MobileProductPage";

const DesktopProductPage = () => (
  <div className="App">
    <div className="navbar-container">
      <Navbar />
      <SecondaryNavBar />
      <DirectoryNavBar />
    </div>
    <ProductDetailPage />
    <Footer />
  </div>
);

export const ProductPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileProductPage /> : <DesktopProductPage />;
};
