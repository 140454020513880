import React, { useEffect, useState } from "react";
import { CategoryOverlay } from "../../header/CategoryOverlay";
import { MobileHeader } from "../../header/MobileHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductBySku,
  getRecommendProductsByCategory,
} from "../../../reducers/products/actions";
import {
  selectProductBySku,
  selectRecommendProducts,
} from "../../../reducers/products/listingSelectors";
import { Link, useNavigate, useParams } from "react-router-dom";
import MobileImageGallery from "./MobileImageGallery";
import { isEmpty, isNil } from "lodash";
import "./mobileProductPage.scss";
import RatingStarIcon from "../../../icons/RatingStarIcon";
import { MobileFooter } from "../../footer/MobileFooter";
import { formatDistance } from "date-fns";
import { mapLocaleToDateLocale } from "../../../utils/locales";
import { addUserTracking } from "../../../reducers/users/actions";
import { PURCHASE } from "../options/userTrackingType";
import { selectListingUser } from "../../../reducers/users/listingSelectors";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { toClassNames } from "../../../utils/strings";
import { Rating } from "@smastrom/react-rating";
import { CouponCodeEmoji } from "../../../icons/CouponCodeEmoji";
import { RiCloseFill } from "react-icons/ri";
import { FiCopy, FiExternalLink } from "react-icons/fi";
import copy from "copy-to-clipboard";
import MobileChatBox from "./MobileChatBox";
import MobileLoginModalOverlay from '../../header/MobileLoginModalOverlay';

export const MobileProductPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);
  const [isExpandProductDescription, setIsExpandProductDescription] =
    useState(true);
  const [isExpandProductReviews, setIsExpandProductReviews] = useState(true);
  const [isShowCodeModal, setIsShowCodeModal] = useState(false);
  const [copyCode, setCopyCode] = useState("product_detail_coupon_code_copy");

  const locale = i18n.language;
  const { sku } = useParams();
  const { id: userId } = useSelector(selectListingUser);
  const product = useSelector(selectProductBySku);
  const recommendProducts = useSelector(selectRecommendProducts);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };
  
  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };

  const toggleExpandProductDescription = () => {
    setIsExpandProductDescription(!isExpandProductDescription);
  };

  const toggleExpandProductReviews = () => {
    setIsExpandProductReviews(!isExpandProductReviews);
  };

  const toggleIsShowCodeModal = () => {
    setIsShowCodeModal(!isShowCodeModal);
  };

  const handleCopyCode = () => {
    copy(couponCode);
    setCopyCode("product_detail_coupon_code_copied");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProductBySku({ sku, navigate, locale }));
  }, [dispatch, sku, navigate, locale]);

  useEffect(() => {
    const { category, sku } = product || {};
    if (category !== "" && sku !== "") {
      dispatch(
        getRecommendProductsByCategory({ category, sku, pageSize: 6, locale }),
      );
    }
  }, [dispatch, product, locale]);

  const handleProductLinkClick = () => {
    if (product && product.category && sku && userId) {
      dispatch(
        addUserTracking({
          userId,
          sku,
          category: product.category,
          type: PURCHASE,
        }),
      );
    }
    window.open(`${product.url}`);
  };

  if (isEmpty(product)) {
    return null;
  }

  const {
    category,
    couponCode,
    description,
    discount,
    endDate,
    imageUrls = "",
    name,
    price = 0,
    listPrice = 0,
    promoCodeExpired,
    productReviews,
    rating,
    ratingCount,
    url,
  } = product;

  const priceDifference = listPrice - price;
  return (
    <div className="mobile-product-page">
      <div className="mobile-product-page-container">
        <MobileHeader toggleCategoryOverlay={toggleCategoryOverlay} />
        <div className="mobile-product-detail-page">
          <div className="mobile-product-item-category-rating-container">
            <div className="mobile-product-item-category">
              <span>{category}</span>
            </div>
            <div className="mobile-product-item-rating">
              <RatingStarIcon
                width={20}
                height={20}
                className={"product-group-item-rating-icon"}
              />
              <span className="product-group-item-rating-number">
                {rating.toFixed(1)}
              </span>
              <span className="product-group-item-rating-count">
                ({ratingCount})
              </span>
            </div>
          </div>
          <div className="mobile-product-item-status-container">
            <div className="mobile-product-item-discount">
              <span>{discount}% OFF</span>
            </div>
          </div>
          <div className="mobile-product-item-name">
            <span>{name}</span>
          </div>
          <div className="product-description-image-gallery">
            <MobileImageGallery images={imageUrls.split("\n")} />
          </div>
          <div className="mobile-product-item-price-container">
            <div className="mobile-product-item-price">
              <span>NOW ${(Math.round(price * 100) / 100).toFixed(2)}</span>
            </div>
            {priceDifference > 0 && (
              <div className="mobile-product-item-list-price-container">
                <span className="mobile-product-item-list-price">
                  ${(Math.round(listPrice * 100) / 100).toFixed(2)}
                </span>
              </div>
            )}
          </div>
          <div className="mobile-product-item-buttons">
            {!promoCodeExpired && (
              <div className="mobile-product-item-product-button-container">
                <div
                  className="mobile-product-item-product-button mobile-product-item-product-code"
                  onClick={() => toggleIsShowCodeModal()}
                >
                  <span>{t("product_detail_get_code")}</span>
                </div>
                <div className="mobile-product-item-product-button-subcontent">
                  <span>{t("product_detail_coupon_code_instruction")}</span>
                </div>
              </div>
            )}
            <div className="mobile-product-item-product-button-container">
              <div
                className="mobile-product-item-product-button mobile-product-item-product-link"
                onClick={handleProductLinkClick}
              >
                <span>{t("product_detail_purchase_title")}</span>
              </div>
              {!promoCodeExpired && (
                <div className="mobile-product-item-product-button-subcontent">
                  <span className="mobile-product-item-expiring-date">
                    {!isNil(endDate)
                      ? t("product_detail_expired_time", {
                          expiredTime: formatDistance(
                            new Date(endDate),
                            new Date(),
                            {
                              locale: mapLocaleToDateLocale({
                                i18nLocale: locale,
                              }),
                            },
                          ),
                        })
                      : t("product_detail_expired_soon")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="mobile-product-item-details">
            <div
              className="mobile-product-item-details-title"
              onClick={() => toggleExpandProductDescription()}
            >
              <span>{t("product_detail_tabs_about_this_product")}</span>
              {isExpandProductDescription ? (
                <FaChevronDown className="icon" size={20} />
              ) : (
                <FaChevronRight className="icon" size={20} />
              )}
            </div>
            <ul
              className={toClassNames([
                "mobile-product-item-details-content",
                isExpandProductDescription ? "visible" : "hidden",
              ])}
            >
              {description.split("\n").map((content, idx) => (
                <li key={idx}>{content}</li>
              ))}
            </ul>
          </div>
          {productReviews.length > 0 && (
            <div className="mobile-product-item-reviews-container">
              <div
                className="mobile-product-item-reviews-title"
                onClick={() => toggleExpandProductReviews()}
              >
                <span>{t("product_detail_tabs_product_reviews")}</span>
                {isExpandProductReviews ? (
                  <FaChevronDown className="icon" size={20} />
                ) : (
                  <FaChevronRight className="icon" size={20} />
                )}
              </div>
              <div
                className={toClassNames([
                  "mobile-product-item-reviews",
                  isExpandProductReviews ? "visible" : "hidden",
                ])}
              >
                {productReviews.map(
                  (
                    { date, title, body, link, profileName, rating = 5 },
                    idx,
                  ) => (
                    <div key={idx} className="product-review-container">
                      <div className="product-review-row product-review-rating">
                        <Rating style={{ maxWidth: 100 }} value={rating} />
                        <div className="product-review-date">{date}</div>
                      </div>
                      <div className="product-review-title product-review-row">
                        {title}
                      </div>
                      <div className="product-review-row">
                        {body.replace("Read more", "")}
                      </div>
                      <div className="product-review-row">
                        <div className="product-review-profile">
                          {profileName}
                        </div>
                        <div
                          className="product-review-link"
                          onClick={() => window.open(`${link}`)}
                        >
                          {t("product_detail_tabs_product_reviews_read_more")}
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
          <MobileChatBox sku={product.sku} />
          <div className="mobile-product-item-recommended-products-container">
            <div className="mobile-product-item-recommended-products-title">
              <span>{t("product_detail_recommend_title")}</span>
            </div>
            <div className="mobile-product-item-recommended-products-list">
              {recommendProducts.map(
                (
                  {
                    discount,
                    imageUrl,
                    name,
                    price,
                    listPrice,
                    rating,
                    ratingCount,
                    sku,
                  },
                  idx,
                ) => (
                  <Link
                    className="mobile-product-item-recommended-product"
                    key={idx}
                    to={`/product/${sku}`}
                  >
                    <div className="mobile-product-item-recommended-product-image">
                      <img
                        src={imageUrl}
                        alt="Product"
                        className="recommend-product-image"
                      />
                    </div>
                    <div className="mobile-product-item-recommended-product-content">
                      <div className="mobile-product-item-recommended-product-discount">
                        <span>{discount} % OFF</span>
                      </div>
                      <div className="mobile-product-item-recommended-product-name">
                        {name}
                      </div>
                      <div className="mobile-product-item-recommended-product-price-container">
                        <div className="mobile-product-item-recommended-product-price">
                          ${(Math.round(price * 100) / 100).toFixed(2)}
                        </div>
                        <div className="mobile-product-item-recommended-product-listPrice">
                          ${(Math.round(listPrice * 100) / 100).toFixed(2)}
                        </div>
                        <div className="mobile-product-item-recommended-product-rating">
                          <RatingStarIcon
                            width={20}
                            height={20}
                            className={"product-group-item-rating-icon"}
                          />
                          <span className="product-group-item-rating-number">
                            {rating.toFixed(1)}
                          </span>
                          <span className="product-group-item-rating-count">
                            ({ratingCount})
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
        <MobileFooter />
      </div>
      <div
        className={toClassNames([
          "mobile-product-item-coupon-code-container",
          isShowCodeModal ? "active" : "",
        ])}
      >
        <div className="mobile-product-item-coupon-code-header">
          <div className="mobile-product-item-coupon-code-emoji">
            <CouponCodeEmoji width={80} height={80} />
          </div>
          <div className="mobile-product-item-coupon-code-title-container">
            <div
              className="mobile-product-item-coupon-code-title-row close-button-container"
              onClick={() => toggleIsShowCodeModal()}
            >
              <RiCloseFill size={24} className="coupon-code-close-button" />
            </div>
            <div className="mobile-product-item-coupon-code-title-row coupon-code-text">
              <span>You have saved ${priceDifference.toFixed(2)}</span>
            </div>
            <div className="mobile-product-item-coupon-code-title-row coupon-code-expired-soon-text">
              <span>{t("product_detail_coupon_code_saving_content")}</span>
            </div>
          </div>
        </div>
        <div className="mobile-product-item-coupon-code-body">
          <div className="mobile-product-item-coupon-code-body-row">
            <div className="mobile-product-item-coupon-code-copy-container">
              <div className="mobile-product-item-coupon-code-copy-text">
                <span>{couponCode}</span>
              </div>
              <div
                className="mobile-product-item-coupon-code-copy-button"
                onClick={() => handleCopyCode()}
              >
                <FiCopy className="coupon-code-icon" size={30} />
                <span className="coupon-code-link-text">{t(copyCode)}</span>
              </div>
            </div>
          </div>
          <div
            className="mobile-product-item-coupon-code-body-row"
            onClick={() => window.open(`${url}`)}
          >
            <div className="mobile-product-item-coupon-code-link-container">
              <FiExternalLink className="coupon-code-icon" size={30} />
              <span className="coupon-code-link-text">
                {t("product_detail_coupon_code_link")}
              </span>
            </div>
          </div>
        </div>
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};
