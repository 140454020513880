import { USER_FIRST_NAME, USER_ID, USER_TOKEN } from "../../utils/constants";
import { deleteCookie, setCookies } from "../../utils/cookies";
import * as types from "./actionTypes";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const initialState = () => {
  return Object.assign(
    {},
    {
      user: {
        email: "",
        password: "",
      },
      errors: {},
      token: undefined,
      id: undefined,
      locale: "en",
    },
  );
};

const handleLoginUserSetField = (state, action) => {
  const { name, value } = action.payload;
  const { user, errors = {} } = state;
  if (name === "email") {
    if (!emailRegex.test(value)) {
      return {
        ...state,
        user: { ...user, [name]: value },
        errors: { ...errors, email: "Email format is not valid" },
      };
    } else {
      delete errors.email;
      return {
        ...state,
        user: { ...user, [name]: value },
        errors: { ...errors },
      };
    }
  }
  return { ...state, user: { ...user, [name]: value } };
};

const handleLoginUserSuccess = (state, action) => {
  const { user: { id, firstName, token } = {} } = action.payload;
  setCredentialCookies({ id, firstName, token });
  return { ...state, id, firstName, token };
};

const handleAutoCreateUserSuccess = (state, action) => {
  const { id, token, firstName } = action.payload;
  return { ...state, id, firstName, token };
};

const handleLoginUserFailed = (state, action) => {
  const { errors } = state;
  const { message, errorCode } = action.payload;

  if (errorCode === "password_incorrect") {
    return {
      ...state,
      errors: { ...errors, email: undefined, password: message },
    };
  } else if (errorCode === "email_not_existed") {
    return {
      ...state,
      errors: { ...errors, email: message, password: undefined },
    };
  }
};

const handleAutoLoginUser = (state, action) => {
  const { id, firstName, token } = action.payload;
  setCredentialCookies({ id, firstName, token });
  return { ...state, id, firstName, token };
};

const handleLogoutUser = () => {
  [USER_TOKEN, USER_FIRST_NAME, USER_ID].forEach((cookieName) =>
    deleteCookie(cookieName),
  );
  return initialState();
};

const setCredentialCookies = ({ id, firstName, token }) => {
  [
    { name: USER_TOKEN, value: token },
    { name: USER_FIRST_NAME, value: firstName },
    { name: USER_ID, value: id },
  ].forEach(({ name, value }) => {
    setCookies({ name, value, options: { expiresInDays: 14, secure: true } });
  });
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.USERS_LOGIN_USER_SET_FIELD:
      return handleLoginUserSetField(state, action);
    case types.USERS_LOGIN_USER_SUCCESS:
      return handleLoginUserSuccess(state, action);
    case types.USERS_LOGIN_USER_FAILED:
      return handleLoginUserFailed(state, action);
    case types.USERS_LOGOUT_USER_SUCCESS:
      return handleLogoutUser();
    case types.USERS_AUTO_CREATE_USER_SUCCESS:
      return handleAutoCreateUserSuccess(state, action);
    case types.USERS_AUTO_LOGIN_USER:
      return handleAutoLoginUser(state, action);
    default: {
      return state;
    }
  }
};

export default reducer;
