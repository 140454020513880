export const SORT_ORDER_DESCENDING = "desc";
export const SORT_ORDER_ASCENDING = "asc";

export function compareProperty({
  propertyName,
  comparator = defaultComparator,
  sortOrder = SORT_ORDER_DESCENDING,
}) {
  return compare({
    supplier: (obj) => obj[propertyName],
    comparator,
    sortOrder,
  });
}

// Compare functions
/**
 * A utility function for generating comparison functions to be used in Array.prototype.sort.
 *
 * @param supplier      A function to extract the specific data for comparison between elements. Returns the element itself by default.
 *                      For example, extracting a specific property in an object or converting an element from a String to a Number.
 * @param comparator    A function for how to compare specific elements. Should have the same return type as the compare function. Uses <,> by default.
 *                      For example, using "localeCompare" for strings.
 * @param sortOrder     An enumerated value that defines whether the results will be ascending or descending sort order.
 *
 * @returns {function(*=, *=): number}
 */
export function compare({
  supplier = identity,
  comparator = defaultComparator,
  sortOrder = SORT_ORDER_DESCENDING,
}) {
  return (a, b) => {
    const sortOrderModifier = sortOrder === SORT_ORDER_DESCENDING ? -1 : 1;
    return sortOrderModifier * comparator(supplier(a), supplier(b));
  };
}

// Suppliers
function identity(obj) {
  return obj;
}

// Comparators
function defaultComparator(a, b) {
  if (exists(a) && exists(b)) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  } else if (exists(a) && !exists(b)) {
    return -1;
  } else if (!exists(a) && exists(b)) {
    return 1;
  } else {
    return 0;
  }
}

function exists(value) {
  return value !== undefined && value !== null;
}
