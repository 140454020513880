import React, { useState, useEffect } from "react";
import { FaUser, FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { useModal } from "react-modal-hook";
import { useTranslation } from "react-i18next";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { Link } from "react-router-dom";
import { toastOption } from "../../options/toast";
import { logoutUser } from "../../reducers/users/actions";
import { selectListingUser } from "../../reducers/users/listingSelectors";
import logoImage from "../../images/savvydeal-logo.png";
import "./navbar.scss";
import WelcomeModal from "../WelcomeModal";
import LoginModal from "../LoginModal";
import SignupModal from "../SignupModal";

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectListingUser);
  const { token } = user;
  const [isWelcomeModalVisible, setWelcomeModalVisible] = useState(false);

  const handleShowWelcomeModal = () => {
    setWelcomeModalVisible(true);
    showWelcomeModal();
  };

  const [showWelcomeModal, hideWelcomeModal] = useModal(() => {
    return <WelcomeModal onClose={hideWelcomeModal} />;
  });

  const [showSignupModal, hideSignupModal] = useModal(() => (
    <SignupModal
      showLoginModal={showLoginModal}
      showWelcomeModal={handleShowWelcomeModal}
      onClose={hideSignupModal}
    />
  ));

  const [showLoginModal, hideLoginModal] = useModal(() => (
    <LoginModal onShowSignupModal={showSignupModal} onClose={hideLoginModal} />
  ));

  useEffect(() => {
    if (isWelcomeModalVisible) {
      const timeoutId = setTimeout(() => {
        hideWelcomeModal();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [isWelcomeModalVisible, hideWelcomeModal]);

  const handleProfileClick = () => {
    if (isNil(token)) {
      showLoginModal();
    } else {
      dispatch(logoutUser());
      toast.success("Logout successfully", toastOption);
    }
  };

  const handleFavoriteItemsClick = () => {
    if (isNil(token)) {
      toast.warn(
        "Please login first to see the your favorite items!",
        toastOption,
      );
    } else {
      // TODO:
    }
  };

  return (
    <div className="redesign-navbar-container">
      <nav className="navbar">
        <div className="navbar-left">
          <div className="logo">
            <AsyncImage
              src={logoImage}
              style={{ width: 200, height: 40, borderRadius: 3 }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
          </div>
          <div className="navigation-links">
            <a href="/">{t("menu_bar_home")}</a>
            <Link to="/products">{t("common_shop_all")}</Link>
            <a href="/about">{t("common_about_us")}</a>
            <a href="/contact">{t("common_support")}</a>
          </div>
        </div>
        <div className="navbar-right">
          <div className="icon-container" onClick={handleProfileClick}>
            <FaUser className="icon" />
          </div>
          <div className="icon-container" onClick={handleFavoriteItemsClick}>
            <FaHeart className="icon" />
          </div>
        </div>
      </nav>
      <ToastContainer />
    </div>
  );
};

export default Navbar;
