import React from "react";
import "./directoryNavBar.scss";
import { useSelector } from "react-redux";
import { selectProductBySku } from "../../reducers/products/listingSelectors";

const DirectoryNavBar = () => {
  const { categories = "" } = useSelector(selectProductBySku);
  const categoryList = categories.split("\n");
  return (
    <div className="directory-navbar">
      <div className="directory-navbar-left">
        {categoryList.map((category, idx) => {
          if (idx < categoryList.length - 1) {
            return (
              <div key={idx}>
                <u>{category}</u>&nbsp;/&nbsp;
              </div>
            );
          } else {
            return <u key={idx}>{category}</u>;
          }
        })}
      </div>
    </div>
  );
};

export default DirectoryNavBar;
