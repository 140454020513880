import React from "react";
import { Button, ButtonContainer } from "./ModalPopup";
import RWDModal from "./RWDModal";
import LogoIcon from "../icons/LogoIcon";
import "./welcomeModal.scss";

const WelcomeModal = ({ onClose }) => {
  return (
    <RWDModal
      onClose={onClose}
      LogoComponent={LogoIcon}
      header={"Welcome To Savvy Deals"}
    >
      <div className="welcome-body">
        You are now a part of the brilliant community that offers countless
        discounts and deals here!
      </div>
      <ButtonContainer>
        <Button onClick={onClose} className="welcome-modal-button">
          <span>Begin to Explore!</span>
        </Button>
      </ButtonContainer>
    </RWDModal>
  );
};

export default WelcomeModal;
