import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getProductsByCategoriesAndFilter } from "../../../reducers/products/actions";
import { CategoryOverlay } from "../../header/CategoryOverlay";
import { MobileHeader } from "../../header/MobileHeader";
import { MobileProductList } from "./MobileProductList";
import { MobileFooter } from "../../footer/MobileFooter";
import "./mobileProductGroupPage.scss";
import { MobileCategoryList } from "../../header/MobileCategoryList";
import {
  selectProducts,
  selectTotalProductsCount,
} from "../../../reducers/products/listingSelectors";
import MobileLoginModalOverlay from '../../header/MobileLoginModalOverlay';

const DEFAULT_PAGE_SIZE = 20;

export const MobileProductGroupPage = () => {
  const { i18n } = useTranslation();
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const locale = i18n.language;
  const [searchParams] = useSearchParams();
  const categories = useMemo(() => {
    return searchParams.get("categories") || "";
  }, [searchParams]);

  const topCategory = useMemo(() => {
    return searchParams.get("topCategory") || "";
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      getProductsByCategoriesAndFilter({
        categories,
        pageNum: currentPage - 1,
        pageSize: DEFAULT_PAGE_SIZE,
        locale,
      }),
    );
  }, [dispatch, categories, locale, currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };
  
  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };

  const products = useSelector(selectProducts);
  const totalResults = useSelector(selectTotalProductsCount);

  return (
    <div className="App">
      <div className="mobile-products-group-container">
        <MobileHeader toggleCategoryOverlay={toggleCategoryOverlay} />
        <MobileCategoryList categories={categories} topCategory={topCategory} />
        <MobileProductList
          products={products}
          totalResults={totalResults}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <MobileFooter />
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};
