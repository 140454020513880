import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./mobileCategoryList.scss";

export const MobileCategoryList = ({ categories, topCategory }) => {
  const { t } = useTranslation();

  return (
    <div className="mobile-product-group-title-directory">
      <Link to="/" className="mobile-product-group-category-link">
        {t("menu_bar_home")}
      </Link>
      &nbsp;/&nbsp;
      <Link to="/products" className="mobile-product-group-category-link">
        All
      </Link>
      {topCategory !== "" && (
        <>
          &nbsp;/&nbsp;
          <Link
            to={`/products?categories=${categories}`}
            className="mobile-product-group-category-link"
          >
            {topCategory}
          </Link>
        </>
      )}
    </div>
  );
};
