import * as types from "./actionTypes";

const initialState = () => {
  return Object.assign(
    {},
    {
      user: {
        email: "",
        password: "",
        confirmedPassword: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        referralCode: "",
      },
      step: 1,
      errors: {},
      id: undefined,
    },
  );
};

const addUser = (state, action) => {
  const { id, firstName, token } = action.payload;
  console.log({ state, action });
  return { ...state, id, firstName, token, signupSuccess: true };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.USERS_SIGNUP_USER_SUCCESS:
      return addUser(state, action);
    case types.USERS_ADD_USER_TRACKING_SUCCESS:
    case types.USERS_USER_SEND_SUPPORT_INQUERY_SUCCESS:
    default:
      return state;
  }
};

export default reducer;
