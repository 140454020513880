import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./secondaryNavBar.scss";
import { FaBars } from "react-icons/fa";
import LanguagesDropdown from "../redesign/LanguageDropdown";
import SearchBar from "../redesign/SearchBar";
import { useTranslation } from "react-i18next";
import { MdOutlineNavigateNext } from "react-icons/md";
import { categories } from "../redesign/options/categories";
import { convertToProductCategoryUrl } from "../../utils/strings";

const SecondaryNavBar = () => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState("Home & Kitchen");
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpanded]);

  return (
    <>
      <div className="secondary-navbar-container">
        <div className="secondary-navbar">
          <div className="secondary-navbar-left">
            <FaBars className="icon" size={16} onClick={toggleDropdown} />
            <span className="shop-category">
              {t("menu_bar_shop_by_category")}
            </span>
          </div>
          <div className="secondary-navbar-middle">
            <SearchBar />
          </div>
          <div className="secondary-navbar-right">
            <LanguagesDropdown />
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="navbar-category-bar-container">
          <div className="navbar-category-dropdown-container" ref={dropdownRef}>
            <div className="navbar-category-dropdown-menu">
              <div className="navbar-main-category-dropdown-title">
                Shop All
              </div>
              {Object.keys(categories).map((category, index) => (
                <div
                  key={index}
                  className={`navbar-category-dropdown-item ${
                    selectedCategory === category ? "selected" : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                  onMouseEnter={() => handleCategoryClick(category)}
                >
                  <span>{category}</span>
                  {selectedCategory === category && (
                    <MdOutlineNavigateNext
                      size={24}
                      className="navbar-category-dropdown-item-icon"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {selectedCategory && (
            <div className="navbar-category-subcategory-menu" ref={dropdownRef}>
              <div className="navbar-sub-category-dropdown-title">Saving</div>
              {categories[selectedCategory].map((subCategory, index) => (
                <Link
                  key={index}
                  className="navbar-category-subcategory-item"
                  to={convertToProductCategoryUrl({
                    category: selectedCategory,
                    subCategory,
                  })}
                  target="_blank"
                >
                  {subCategory}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SecondaryNavBar;
