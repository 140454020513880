import React, { useState } from "react";
import "./categoryOverlay.scss";
import {
  categories,
  categoryIconMapping,
} from "../redesign/options/categories";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { convertToProductCategoryUrl } from "../../utils/strings";
import { FiX } from "react-icons/fi";

export const CategoryOverlay = ({ isOverlayOpen, toggleOverlay }) => {
  const [openCategory, setOpenCategory] = useState(null);
  const navigate = useNavigate();

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  const handleOnCategoryClick = ({ category, subCategory }) => {
    const url = convertToProductCategoryUrl({ category, subCategory });
    toggleOverlay();
    navigate(url);
  };

  return (
    <div
      className={`category-overlay ${isOverlayOpen ? "show" : ""}`}
      onClick={toggleOverlay}
    >
      <div
        className="category-overlay-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header">
          <div className="header-left-col" />
          <div className="header-right-col">
            <FiX className="icon" size={20} onClick={toggleOverlay} />
          </div>
        </div>
        <div className="body">
          <h5 className="category-title">Shop By Categories</h5>
          <div className="categories-list-container">
            {Object.keys(categories).map((category, index) => (
              <div className="category" key={index}>
                <div
                  className="main-category"
                  onClick={() => toggleCategory(category)}
                >
                  {categoryIconMapping[category]}
                  <span className="category-name">{category}</span>
                  {openCategory === category ? (
                    <FaChevronDown className="icon" size={20} />
                  ) : (
                    <FaChevronRight className="icon" size={20} />
                  )}
                </div>
                {openCategory === category && (
                  <div className="sub-category-list">
                    {categories[category].map((subCategory, index) => (
                      <div
                        key={index}
                        className="sub-category"
                        onClick={() =>
                          handleOnCategoryClick({ category, subCategory })
                        }
                      >
                        <span className="sub-category-name">{subCategory}</span>
                        <FaChevronRight className="icon" size={20} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
