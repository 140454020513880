import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "./header/NavBar";
import { Footer } from "./footer/Footer";
import "./notFoundPage.scss";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/");
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="App">
      <Navbar />
      <div className="not-found-page-container">
        <div>
          <img src="./Oops.png" alt="Oops" className="no-found-image" />
        </div>
        <p className="no-found-message">{t("no_page_found_title")}</p>
      </div>
      <Footer />
    </div>
  );
};
