import { AMAZON } from "../../utils/constants";
import { formatInTimeZone } from "date-fns-tz";
import * as types from "./actionTypes";

const initialState = () => {
  return Object.assign(
    {},
    {
      category: {
        id: 1,
        label: "Recommendation",
      },
      load: {
        hasMore: true,
        pageNum: 0,
      },
      search: {
        hasMore: true,
        pageNum: 0,
      },
      query: "",
      platform: AMAZON,
      product: {},
      products: [],
      productsByCategory: [],
      recommendProducts: [],
      trendingProducts: [],
      total: 0,
      searchProducts: [],
      searchTotalResults: 0,
      cutOffDate: getYesterdayMidnightTime(),
    },
  );
};

const getYesterdayMidnightTime = () => {
  const dateTime = new Date();
  dateTime.setDate(dateTime.getDate() - 7);
  dateTime.setHours(14, 0, 0, 0);
  return formatInTimeZone(dateTime, "UTC", "yyyy-MM-dd'T'HH:mm:ss");
};

const handleSetActiveCategoryId = (state, action) => {
  const { category } = action.payload;
  return {
    ...state,
    category,
    productsByCategory: [],
    load: {
      hasMore: true,
      pageNum: 0,
    },
  };
};

const handleResetProductsByCategory = (state) => {
  return {
    ...state,
    productsByCategory: [],
    load: {
      hasMore: true,
      pageNum: 0,
    },
  };
};

const handleSetSearchQuery = (state, action) => {
  const { query } = action.payload;
  return {
    ...state,
    query,
  };
};

const handleSearchProducts = (state, action) => {
  const { products = [], totalResults = 0 } = action.payload;
  console.log({ products });
  return {
    ...state,
    searchProducts: products,
    searchTotalResults: totalResults,
  };
};

const handleGetProductBySku = (state, action) => {
  const { product } = action.payload;
  return { ...state, product };
};

const handleGetProductsByCategory = (state, action) => {
  const {
    productsByCategory,
    load: { pageNum },
  } = state;
  const { products } = action.payload;
  if (products.length === 0) {
    return { ...state, load: { hasMore: false, pageNum: pageNum } };
  }
  return {
    ...state,
    productsByCategory: [...productsByCategory, ...products],
    load: {
      hasMore: true,
      pageNum: pageNum + 1,
    },
  };
};

const handleGetRecommendProductsByCategory = (state, action) => {
  const { products } = action.payload;
  return {
    ...state,
    recommendProducts: products,
  };
};

const handleGetTrendingProducts = (state, action) => {
  const { products } = action.payload;
  return {
    ...state,
    trendingProducts: products,
  };
};

const handleGetProductsByCategories = (state, action) => {
  const { products: newProducts, totalResults = 0 } = action.payload;
  return {
    ...state,
    products: newProducts,
    totalResults,
  };
};

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.PRODUCTS_SET_ACTIVE_PRODUCT_CATEGORY_ID:
      return handleSetActiveCategoryId(state, action);
    case types.PRODUCTS_SET_SEARCH_QUERY:
      return handleSetSearchQuery(state, action);
    case types.PRODUCTS_SEARCH_PRODUCTS:
      return handleSearchProducts(state, action);
    case types.PRODUCTS_GET_PRODUCT_BY_SKU:
      return handleGetProductBySku(state, action);
    case types.PRODUCTS_GET_PRODUCTS_BY_CATEGORY:
      return handleGetProductsByCategory(state, action);
    case types.PRODUCTS_GET_PRODUCTS_BY_CATEGORIES:
      return handleGetProductsByCategories(state, action);
    case types.PRODUCTS_GET_RECOMMEND_PRODUCTS_BY_CATEGORY:
      return handleGetRecommendProductsByCategory(state, action);
    case types.PRODUCTS_GET_TRENDING_PRODUCTS:
      return handleGetTrendingProducts(state, action);
    case types.PRODUCTS_RESET_PRODUCTS_BY_CATEGORY:
      return handleResetProductsByCategory(state);
    default:
      return state;
  }
};

export default reducer;
