import React from "react";
import "./mobileFooter.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoArrowRight } from "react-icons/go";

export const MobileFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="mobile-footer-container">
      <div className="footer-col">
        <div className="footer-title footer-text">{t("coomon_products")}</div>
        <Link className="footer-text footer-link" to="/products">
          SavvyDeal World
        </Link>
        <Link className="footer-text footer-link" to="/about">
          {t("common_about_us")}
        </Link>
      </div>
      <div className="footer-col">
        <div className="footer-text footer-title">{t("common_support")}</div>
        <Link className="footer-text footer-link" to="/privacy">
          {t("footer_category_others_privacy_policy")}
        </Link>
        <Link className="footer-text footer-link" to="/termsofservice">
          {t("footer_category_others_terms_of_service")}
        </Link>
      </div>
      <div className="footer-col">
        <div className="footer-title footer-text">
          {t("footer_category_help_contact_us")}
        </div>
        <div className="footer-text">
          <span>support@savvydeal.ai</span>
        </div>
        <div className="footer-text" />
      </div>
      <div className="footer-col">
        <div className="footer-title footer-text">
          {t("footer_category_stay_in_touch")}
        </div>
        <div className="footer-text footer-email-container">
          <input
            type="text"
            id="email"
            placeholder={t("modal_email_field_placeholder")}
          />
          <GoArrowRight size={30} />
        </div>
        <div className="footer-text" />
        <div className="footer-text" />
      </div>
    </div>
  );
};
