import * as types from "./actionTypes";
import * as api from "../../api";

export const setActiveProductCategory = (category) => {
  return {
    type: types.PRODUCTS_SET_ACTIVE_PRODUCT_CATEGORY_ID,
    payload: { category },
  };
};

export const setSearchQuery = (query) => {
  return { type: types.PRODUCTS_SET_SEARCH_QUERY, payload: { query } };
};

export const searchProductsByQuery = ({
  query,
  navigate,
  pageNum,
  pageSize,
}) => {
  return (dispatch) => {
    api
      .searchProducts({ query, pageNum, pageSize })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_SEARCH_PRODUCTS,
          payload: {
            products: res.data.products,
            totalResults: res.data.totalResults,
          },
        });
        if (navigate !== undefined) {
          navigate({
            pathname: "/search",
            search: `?q=${query}`,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};

export const getProductBySku = ({ sku, navigate, locale }) => {
  return (dispatch) => {
    api
      .getProductBySku({ sku, locale })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_GET_PRODUCT_BY_SKU,
          payload: { product: res.data },
        });
      })
      .catch((err) => {
        console.log({ err });
        navigate({
          pathname: "/",
        });
      });
  };
};

export const getProductsByCategory = ({ category, pageNum, locale }) => {
  return (dispatch) => {
    api
      .getProductByCategoryWithPageSize({
        category,
        pageNum,
        locale,
      })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_GET_PRODUCTS_BY_CATEGORY,
          payload: { products: res.data },
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};

export const getProductsByCategoriesAndFilter = ({
  categories = [],
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
  minPrice = 0,
  maxPrice = 800,
  minDiscount = 10,
  minRating = 1,
  sortOrder,
}) => {
  return (dispatch) =>
    api
      .getProductsByCategoriesAndFilterAndPage({
        categories,
        pageNum,
        pageSize,
        locale,
        minPrice,
        maxPrice,
        minDiscount,
        minRating,
        sortOrder,
      })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_GET_PRODUCTS_BY_CATEGORIES,
          payload: {
            products: res.data.products,
            totalResults: res.data.totalResults,
          },
        });
      })
      .catch((err) => {
        console.log({ err });
      });
};

export const getRecommendProductsByCategory = ({
  category,
  sku,
  pageSize,
  locale,
}) => {
  return (dispatch) => {
    api
      .getRecommendProductsByCategory({
        category,
        sku,
        pageSize,
        locale,
      })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_GET_RECOMMEND_PRODUCTS_BY_CATEGORY,
          payload: { products: res.data },
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};

export const getTrendingProducts = ({ pageNum, pageSize, locale }) => {
  return (dispatch) => {
    api
      .getTrendingProducts({
        pageNum,
        pageSize,
        locale,
      })
      .then((res) => {
        dispatch({
          type: types.PRODUCTS_GET_TRENDING_PRODUCTS,
          payload: { products: res.data },
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};

export const resetProductsByCateogory = () => {
  return { type: types.PRODUCTS_RESET_PRODUCTS_BY_CATEGORY };
};
