import { compareProperty, SORT_ORDER_DESCENDING } from "./sort";

export const getHotAndLimitedProducts = (products = []) => {
  const hotProductSkus =
    products
      .sort(
        compareProperty({
          propertyName: "ratingCount",
          sortOrder: SORT_ORDER_DESCENDING,
        }),
      )
      .map(({ sku }) => sku)
      .slice(0, 3) ?? [];
  const limitedProductSkus =
    products
      .sort(
        compareProperty({
          propertyName: "price",
          sortOrder: SORT_ORDER_DESCENDING,
        }),
      )
      .map(({ sku }) => sku)
      .slice(0, 3) ?? [];
  return { hotProductSkus, limitedProductSkus };
};
