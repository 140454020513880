import React from "react";
import "./recommendProductsTab.scss";
import { useTranslation } from "react-i18next";

const RecommendProductsTab = ({ recommendProducts = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="recommend-products-tab">
      <div className="tab-buttons">
        <button className="tab-button active">
          <span>{t("product_detail_recommend_title")}</span>
        </button>
      </div>
      <div className="tab-content">
        {recommendProducts.map((recommendProduct, idx) => (
          <div className="tab-panel" key={idx}>
            <div
              className="recommend-product-details"
              onClick={() =>
                window.open(`/product/${recommendProduct.sku}`, "_blank")
              }
            >
              <div className="col recommend-product-image-container">
                <img
                  src={recommendProduct.imageUrl}
                  alt="Product"
                  className="recommend-product-image"
                />
              </div>
              <div className="col">
                <div className="row">
                  <div className="recommend-discount-container">
                    <span>{recommendProduct.discount}% OFF</span>
                  </div>
                  <div className="recommend-product-title-container">
                    <span>{recommendProduct.name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="recommend-product-price-container">
                    <div className="column asking-price">
                      <span>
                        $
                        {(
                          Math.round(recommendProduct.price * 100) / 100
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="column original-price">
                      <span>
                        $
                        {(
                          Math.round(recommendProduct.listPrice * 100) / 100
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="column recommend-product-rating-icon-text">
                      <div className="row recommend-product-rating-icon">
                        <svg
                          width="25"
                          height="23"
                          viewBox="0 0 25 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.8287 1.5833L15.229 8.79519L15.3429 9.13729H15.7034H23.437L17.1908 13.5676L16.8887 13.7819L17.0057 14.1333L19.3979 21.321L13.1179 16.8667L12.8287 16.6616L12.5394 16.8667L6.2594 21.321L8.65167 14.1333L8.76864 13.7819L8.46652 13.5676L2.2203 9.13729H9.95394H10.3145L10.4284 8.79519L12.8287 1.5833Z"
                            fill="#FFDD29"
                            stroke="black"
                          />
                        </svg>
                      </div>
                      <div className="row recommend-product-rating-text">
                        <span>
                          <b>{recommendProduct.rating}</b>
                        </span>
                      </div>
                      <div className="row recommend-product-rating-number-of-comments">
                        <span>({recommendProduct.ratingCount})</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendProductsTab;
