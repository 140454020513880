import React, { useState } from "react";
import "./productTabs.scss";
import { useTranslation } from "react-i18next";
import { Rating } from "@smastrom/react-rating";

const ProductTabs = ({ descriptions = [], productReviews = [] }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs">
      <div className="tab-buttons">
        <button
          className={`tab-button ${activeTab === 0 ? "active" : ""}`}
          onClick={() => handleTabClick(0)}
        >
          {t("product_detail_tabs_about_this_product")}
        </button>
        {productReviews.length > 0 && (
          <button
            className={`tab-button ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            {t("product_detail_tabs_product_reviews")}
          </button>
        )}
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <div className="tab-panel product-content">
            <ul>
              {descriptions.map((content, idx) => (
                <li key={idx}>{content}</li>
              ))}
            </ul>
          </div>
        )}
        {activeTab === 1 && (
          <div className="tab-panel product-content">
            {productReviews.map(
              ({ date, title, body, link, profileName, rating = 5 }, idx) => (
                <div key={idx} className="product-review-container">
                  <div className="product-review-row">
                    <Rating style={{ maxWidth: 100 }} value={rating} />
                    <div className="product-review-date">{date}</div>
                  </div>
                  <div className="product-review-title product-review-row">
                    {title}
                  </div>
                  <div className="product-review-row">
                    {body.replace("Read more", "")}
                  </div>
                  <div className="product-review-row">
                    <div className="product-review-profile">{profileName}</div>
                    <div
                      className="product-review-link"
                      onClick={() => window.open(`${link}`, "_blank")}
                    >
                      {t("product_detail_tabs_product_reviews_read_more")}
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductTabs;
