import React from "react";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import logoImage from "../../images/savvydeal-logo.png";
import "./mobileHeader.scss";
import SearchBar from "../redesign/SearchBar";
import LanguageDropdown from "../redesign/LanguageDropdown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectListingUser } from "../../reducers/users/listingSelectors";
import { isEmpty } from "lodash";
import { googleLogout } from "@react-oauth/google";
import { logoutUser } from "../../reducers/users/actions";
import { toastOption } from "../../options/toast";
import { toast } from "react-toastify";
import { FiHeart, FiUser } from "react-icons/fi";
import { selectLoginCredential } from '../../reducers/users/createSelectors';

export const MobileHeader = ({ toggleCategoryOverlay, toggleLoginOverlay }) => {
  const dispatch = useDispatch();
  const { token } = useSelector(selectListingUser);
  const { token: signupToken } = useSelector(selectLoginCredential);
  const isLogin = !isEmpty(token) || !isEmpty(signupToken);

  const handleUserIconClick = () => {
    if (isLogin) {
      googleLogout();
      dispatch(logoutUser());
      toast.success("Logout successfully", toastOption);
    } else {
      toggleLoginOverlay();
    }
  };

  return (
    <div className="mobile-main-page-header">
      <div className="mobile-main-page-header-row1">
        <div className="mobile-main-page-header-left-column">
          <div className="col">
            <FaBars
              className="icon"
              size={20}
              onClick={toggleCategoryOverlay}
            />
          </div>
        </div>
        <Link className="mobile-main-page-header-middle-column" to="/">
          <AsyncImage
            src={logoImage}
            style={{ width: 200, height: 40, borderRadius: 3 }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
        </Link>
        <div className="mobile-main-page-header-right-column">
          <div className="col">
            {isLogin ? (
              <FaSignOutAlt
                className="icon"
                size={20}
                onClick={handleUserIconClick}
              />
            ) : (
              <FiUser
                className="icon"
                size={20}
                onClick={handleUserIconClick}
              />
            )}
          </div>
          <div className="col">
            <FiHeart className="icon" size={20} />
          </div>
        </div>
      </div>
      <LanguageDropdown />
      <div className="mobile-main-page-header-row2">
        <SearchBar />
      </div>
    </div>
  );
};
