import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { useGoogleLogin } from "@react-oauth/google";
import "./mobileLoginOverlay.scss";
import { toastOption } from "../../options/toast";
import * as api from "../../api";
import {
  addAutoCreateUser,
  addUser,
  loginUser,
} from "../../reducers/users/actions";
import { useTranslation } from "react-i18next";
import {
  FiGift,
  FiKey,
  FiLock,
  FiMail,
  FiPhone,
  FiUser,
  FiX,
} from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";

const defaultValues = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  referralCode: "",
};

const MobileLoginModalOverlay = ({ isOverlayOpen, toggleOverlay }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailExists, setEmailExists] = useState(false);
  const [shouldSignup, setShouldSignup] = useState(false);
  const [isPasswordHidden, setPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setConfirmPasswordHidden] = useState(true);

  const {
    control,
    formState: { errors },
    watch,
    setError,
    setValue,
    trigger,
  } = useForm({ defaultValues });

  const allFields = watch();
  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    phoneNumber,
    referralCode,
  } = allFields;

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { access_token } = tokenResponse;
      try {
        const response = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          },
        );
        const { given_name, family_name, email } = await response.json();
        dispatch(
          addAutoCreateUser({
            user: {
              email,
              firstName: given_name,
              lastName: family_name,
              languageIsoCode: "en",
            },
          }),
        );
        toggleOverlay();
        toast.success(`Welcome back ${given_name}`);
      } catch (error) {
        toast.error("Unable to login from google", toastOption);
      }
    },
    onError: () => {
      toast.error("Unable to login from google", toastOption);
    },
  });

  const handleOnNextClick = async () => {
    try {
      const { valid } = await api
        .checkEmailExists(email)
        .then((res) => res.data);
      if (!valid) {
        setEmailExists(true);
      } else if (valid) {
        setShouldSignup(true);
      }
    } catch (error) {
      console.error("Error checking email:", error);
      setError("email", {
        type: "manual",
        message: "Unable to validate email, please try again later",
      });
    }
  };

  const handleOnLoginClick = (event) => {
    event.stopPropagation();
    dispatch(loginUser({ data: { email, password }, onClose: toggleOverlay }));
  };

  const handleSignupClick = (event) => {
    event.stopPropagation();
    const data = {
      email,
      password,
      confirmPassword,
      languageIsoCode: "en",
      firstName,
      lastName,
      phoneNumber,
      referralCode,
    };
    dispatch(addUser({ data }));
    toggleOverlay()
    toast.success(`${firstName}, Welcome to SavvyDeal!`);
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const phoneNumber = value.replace(/\D/g, "");
    // Format the phone number as (XXX)-XXX-XXXX
    return phoneNumber
      .slice(0, 10)
      .replace(
        /(\d{3})(\d{3})(\d{0,4})/,
        (match, p1, p2, p3) => `(${p1})-${p2}${p3 ? `-${p3}` : ""}`,
      );
  };

  const isNextButtonDisabled = isEmpty(email) || !isEmpty(errors);
  const isSignupSubmitButtonDisabled =
    isEmpty(email) ||
    isEmpty(password) ||
    isEmpty(confirmPassword) ||
    isEmpty(phoneNumber) ||
    isEmpty(firstName) ||
    isEmpty(lastName) ||
    !isEmpty(errors);

  return (
    <>
      <div
        className={`login-modal-overlay ${isOverlayOpen ? "show" : ""}`}
        onClick={toggleOverlay}
      >
        <div
          className="login-modal-overlay-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="header">
            <div className="header-left-col" />
            <div className="header-right-col">
              <FiX className="icon" size={20} onClick={toggleOverlay} />
            </div>
          </div>
          <div className="body">
            {!shouldSignup ? (
              <>
                <span className="login-modal-title">
                  {t("login_modal_title")}
                </span>
                <div className="modal-container">
                  <div className="row email-input-container">
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Email regex pattern
                          message: "Enter a valid email", // Error message for invalid email
                        },
                      }}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="user-email"
                            pattern="[^\\s@]+@[^\\s@]+\\..+"
                            placeholder="Email"
                            onBlur={onBlur}
                            value={email}
                            onChange={(e) => {
                              onChange(e);
                              trigger("email");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          {isEmpty(email) ? (
                            <FiMail className="icon" size={20} />
                          ) : (
                            <FiX
                              className="icon"
                              size={20}
                              onClick={() => {
                                setValue("email", "");
                                trigger("email");
                              }}
                            />
                          )}
                        </div>
                      )}
                    />
                    {errors && (
                      <span className="error-message">
                        {errors?.email?.message}
                      </span>
                    )}
                  </div>
                  {emailExists && (
                    <div className="row password-input-container">
                      <Controller
                        name="password"
                        control={control}
                        rules={{
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                        }}
                        render={({ field: { onChange, onBlur, ref } }) => (
                          <div className="input-container">
                            <input
                              id="password"
                              type={isPasswordHidden ? "password" : "text"}
                              placeholder="Password"
                              onBlur={onBlur}
                              onChange={(e) => {
                                onChange(e);
                                trigger("password"); // Trigger validation on change
                              }}
                              ref={ref}
                              className="input-field"
                            />
                            <FiLock
                              className="icon"
                              size={20}
                              onClick={() =>
                                setPasswordHidden(!isPasswordHidden)
                              }
                            />
                          </div>
                        )}
                      />
                      {errors?.password && (
                        <span className="error-message">
                          {errors?.password?.message}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="row">
                    {!emailExists ? (
                      <button
                        className={`form-button ${
                          isNextButtonDisabled ? "disabled" : "enabled"
                        }`}
                        disabled={isNextButtonDisabled}
                        onClick={handleOnNextClick}
                      >
                        <span>{t("common_next")}</span>
                      </button>
                    ) : (
                      <button
                        className={`form-button ${
                          !isEmpty(errors) ? "disabled" : "enabled"
                        }`}
                        disabled={!isEmpty(errors)}
                        onClick={handleOnLoginClick}
                      >
                        <span>{t("common_signin")}</span>
                      </button>
                    )}
                  </div>
                  <div className="row divider-row">
                    <div className="divider">
                      <span className="divider-text">or</span>
                    </div>
                  </div>
                  <div className="row google-button-row">
                    <button
                      className="google-button"
                      onClick={() => handleGoogleLogin()}
                    >
                      <FcGoogle className="google-icon" size={20} />
                      <span>{t("login_modal_continue_with_google")}</span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span className="signup-modal-title">
                  {t("signup_modal_header")}
                </span>
                <span className="signup-modal-subtitle">
                  {t("signup_modal_message")}
                </span>
                <div className="modal-container">
                  <div className="row signup-user-info">
                    <span className="signup-user-email">{email}</span>
                    <span
                      className="signup-go-back"
                      onClick={() => setShouldSignup(false)}
                    >
                      Not your email? Go back
                    </span>
                  </div>
                  <div className="row">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: "First Name is required",
                        minLength: {
                          value: 1,
                          message: "First Name is required",
                        },
                      }}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="firstName"
                            type="text"
                            placeholder={t(
                              "modal_first_name_field_placeholder",
                            )}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                              trigger("firstName");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          <FiUser className="icon" size={20} />
                        </div>
                      )}
                    />
                    {errors?.firstName && (
                      <span className="error-message">
                        {errors?.firstName?.message}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{ required: "Last Name is required" }}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="lastName"
                            type="text"
                            placeholder={t("modal_last_name_field_placeholder")}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                              trigger("lastName");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          <FiUser className="icon" size={20} />
                        </div>
                      )}
                    />
                    {errors?.lastName && (
                      <span className="error-message">
                        {errors?.lastName?.message}
                      </span>
                    )}
                  </div>

                  <div className="row">
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: "Phone number is required",
                        pattern: {
                          value: /^\(\d{3}\)-\d{3}-\d{4}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <div className="input-container">
                          <input
                            id="phoneNumber"
                            type="tel"
                            placeholder={t(
                              "modal_phone_number_field_placeholder",
                            )}
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            value={formatPhoneNumber(value || "")}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                              trigger("phoneNumber");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          <FiPhone className="icon" size={20} />
                        </div>
                      )}
                    />
                    {errors?.phoneNumber && (
                      <span className="error-message">
                        {errors?.phoneNumber?.message}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <Controller
                      name="password"
                      control={control}
                      rules={{
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        pattern: {
                          value:
                            /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/,
                          message: t("signup_modal_password_format"),
                        },
                      }}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="password"
                            type={isPasswordHidden ? "password" : "text"}
                            placeholder={t("modal_password_field_placeholder")}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                              trigger("password");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          <FiLock
                            className="icon"
                            size={20}
                            onClick={() => setPasswordHidden(!isPasswordHidden)}
                          />
                        </div>
                      )}
                    />
                    {errors?.password && (
                      <span className="error-message">
                        {errors?.password?.message}
                      </span>
                    )}
                  </div>
                  <div className="row confirm-password-input-field">
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{
                        required: "Confirm Password is required",
                        validate: (value) =>
                          value === control._formValues.password ||
                          "Passwords do not match",
                      }}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="confirmPassword"
                            type={isConfirmPasswordHidden ? "password" : "text"}
                            placeholder={t(
                              "modal_confirm_password_field_placeholder",
                            )}
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e);
                              trigger("confirmPassword");
                            }}
                            ref={ref}
                            className="input-field"
                          />
                          <FiKey
                            className="icon"
                            size={20}
                            onClick={() =>
                              setConfirmPasswordHidden(!isConfirmPasswordHidden)
                            }
                          />
                        </div>
                      )}
                    />
                    {errors?.confirmPassword && (
                      <span className="error-message">
                        {errors?.confirmPassword?.message}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <Controller
                      name="referralCode"
                      control={control}
                      rules={{}}
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <div className="input-container">
                          <input
                            id="referralCode"
                            type="text"
                            placeholder={
                              `Optional: ` +
                              t("modal_refer_code_field_placeholder")
                            }
                            onBlur={onBlur}
                            onChange={onChange}
                            ref={ref}
                            className="input-field"
                          />
                          <FiGift className="icon" size={20} />
                        </div>
                      )}
                    />
                  </div>

                  <div className="row">
                    <button
                      className={`form-button ${
                        isSignupSubmitButtonDisabled ? "disabled" : "enabled"
                      }`}
                      disabled={isSignupSubmitButtonDisabled}
                      onClick={handleSignupClick}
                    >
                      <span>{t("common_submit")}</span>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MobileLoginModalOverlay;
