import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Blur } from "transitions-kit";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";
import CategoryBanner from "../header/CategoryBanner";
import { SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING } from "../../utils/sort";
import { getHotAndLimitedProducts } from "../../utils/products";
import RatingStarIcon from "../../icons/RatingStarIcon";
import {
  selectProducts,
  selectTotalProductsCount,
} from "../../reducers/products/listingSelectors";
import { getProductsByCategoriesAndFilter } from "../../reducers/products/actions";
import Pagination from "./Pagination";
import { Footer } from "../footer/Footer";
import { toClassNames } from "../../utils/strings";
import "./productGroupPage.scss";
import { useIsMobile } from "./hook/layoutHooks";
import { MobileProductGroupPage } from "./mobile/MobileProductGroupPage";

const DEFAULT_PAGE_NUM = 0;
const DEFAULT_PAGE_SIZE = 20;
const MIN = 0;
const MAX = 800;
const STEP = 8;
const DEFAULT_DISCOUNT = 10;
const DEFAULT_RATING = 2;

const DesktopProductGroupPage = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const locale = i18n.language;

  const [sortingOrder, setSortingOrder] = useState({
    name: "products_sort_price_desc",
    key: SORT_ORDER_DESCENDING,
  });
  const [discountOption, setDiscountOption] = useState(DEFAULT_DISCOUNT);
  const [ratingOption, setRatingOption] = useState(DEFAULT_RATING);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMenuOpen, setSortMenuOpen] = useState(false);

  const totalResults = useSelector(selectTotalProductsCount);
  const [priceRange, setPriceRange] = useState([MIN, MAX]);
  const [sliderRange, setSliderRange] = useState([0, 100]);

  const [searchParams] = useSearchParams();
  const categories = useMemo(() => {
    return searchParams.get("categories") || "";
  }, [searchParams]);

  const topCategory = useMemo(() => {
    return searchParams.get("topCategory") || "";
  }, [searchParams]);

  const sortOrderItems = [
    {
      name: "products_sort_price_desc",
      key: SORT_ORDER_DESCENDING,
    },
    {
      name: "products_sort_price_asc",
      key: SORT_ORDER_ASCENDING,
    },
  ];

  const createRatingStars = (fullStars) => {
    return (
      <div className="star-rating">
        {[...Array(fullStars)].map((_, idx) => (
          <MdOutlineStar key={idx} size={20} />
        ))}
        {[...Array(5 - fullStars)].map((_, idx) => (
          <MdOutlineStarBorder key={idx} size={20} />
        ))}
        &nbsp;and up
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePriceRangeChange = (range) => {
    setSliderRange(range);
    const newRange = range.map((val) => val * STEP);
    setPriceRange(newRange);
  };

  const handleSortByOptionChange = (option) => {
    setSortingOrder(option);
    setSortMenuOpen(false);
  };

  useEffect(() => {
    setDiscountOption(DEFAULT_DISCOUNT);
    setRatingOption(DEFAULT_RATING);
    setSliderRange([0, 100]);
    setPriceRange([MIN, MAX]);
    setSortingOrder({
      name: "products_sort_price_desc",
      key: SORT_ORDER_DESCENDING,
    });
  }, [categories]);

  useEffect(() => {
    dispatch(
      getProductsByCategoriesAndFilter({
        categories,
        pageNum: currentPage - 1,
        pageSize: DEFAULT_PAGE_SIZE,
        locale,
        minDiscount: discountOption,
        minRating: ratingOption,
        sortOrder: sortingOrder.key,
        minPrice: priceRange[0],
        maxPrice: priceRange[1],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, categories, locale, currentPage]);

  const handleOnFilterClearClick = () => {
    setDiscountOption(DEFAULT_DISCOUNT);
    setRatingOption(DEFAULT_RATING);
    setSliderRange([0, 100]);
    setPriceRange([MIN, MAX]);

    dispatch(
      getProductsByCategoriesAndFilter({
        categories,
        pageNum: DEFAULT_PAGE_NUM,
        pageSize: DEFAULT_PAGE_SIZE,
        locale,
        minDiscount: DEFAULT_DISCOUNT,
        minRating: DEFAULT_RATING,
        sortOrder: SORT_ORDER_DESCENDING,
        minPrice: MIN,
        maxPrice: MAX,
      }),
    );
  };

  const handleOnFilterApplyClick = () => {
    setCurrentPage(1);
    dispatch(
      getProductsByCategoriesAndFilter({
        categories,
        pageNum: 0,
        pageSize: 20,
        locale,
        minDiscount: discountOption,
        minRating: ratingOption,
        sortOrder: sortingOrder.key,
        minPrice: priceRange[0],
        maxPrice: priceRange[1],
      }),
    );
  };

  const products = useSelector(selectProducts);

  const hasFilter =
    discountOption !== DEFAULT_DISCOUNT ||
    ratingOption !== DEFAULT_RATING ||
    priceRange[0] !== MIN ||
    priceRange[1] !== MAX;

  const { hotProductSkus, limitedProductSkus } =
    getHotAndLimitedProducts(products);

  return (
    <div className="App">
      <div className="navbar-container">
        <Navbar />
        <SecondaryNavBar />
        {categories === "Home & Kitchen" && (
          <CategoryBanner categories={categories} />
        )}
      </div>
      <div className="product-group-container">
        <div className="product-group-row product-group-title">
          <div className="product-group-title-directory">
            <Link to="/" className="product-group-category-link">
              {t("menu_bar_home")}
            </Link>
            &nbsp;/&nbsp;
            <Link to="/products" className="product-group-category-link">
              All
            </Link>
            {topCategory !== "" && (
              <>
                &nbsp;/&nbsp;
                <Link
                  to={`/products?categories=${categories}`}
                  className="product-group-category-link"
                >
                  {topCategory}
                </Link>
              </>
            )}
          </div>
          <div className="product-group-title-sorting">
            <div
              className="product-group-sorting-btn"
              onClick={() => {
                setSortMenuOpen((prev) => !prev);
              }}
            >
              <span>{t("products_sort_price_1")}</span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span>{t(sortingOrder.name)}</span>
              &nbsp;&nbsp;
              {sortingOrder === SORT_ORDER_DESCENDING ? (
                <IoIosArrowDown />
              ) : (
                <IoIosArrowUp />
              )}
            </div>
            <div
              className={toClassNames([
                "product-group-sorting-content",
                !sortMenuOpen ? "product-group-sorting-content-hidden" : "",
              ])}
            >
              <ul className="product-group-sorting-options">
                {sortOrderItems.map(({ name, key }, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleSortByOptionChange({ name, key })}
                  >
                    {t(name)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="product-group-row product-group-body">
          <div className="product-group-body-filter">
            <div className="product-group-body-filter-apply group-filter">
              <div
                className="product-group-body-filter-clearall product-group-body-filter-btn"
                onClick={() => handleOnFilterClearClick()}
              >
                {t("products_filter_clear")}
              </div>
              <div
                className="product-group-body-filter-apply product-group-body-filter-btn"
                onClick={() => handleOnFilterApplyClick()}
              >
                {t("products_filter_apply")}
              </div>
            </div>
            <div className="product-group-body-filter-price-range group-filter">
              <div className="product-group-body-filter-price-range-title">
                <div className="product-group-body-filter-price-range-title-left">
                  Price Range
                </div>
                <div className="product-group-body-filter-price-range-title-right">
                  ${priceRange[0]} - ${priceRange[1]}
                </div>
              </div>
              <div className="product-group-body-filter-price-range-slider">
                <ReactSlider
                  className="price-range-slider"
                  thumbClassName="price-range-thumb"
                  trackClassName="price-range-track"
                  defaultValue={[0, 100]}
                  ariaLabel={["Lower thumb", "Upper thumb"]}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}>{""}</div>}
                  pearling
                  minDistance={0}
                  value={sliderRange}
                  onChange={(range) => handlePriceRangeChange(range)}
                />
              </div>
            </div>
            <div className="product-group-body-filter-discount group-filter">
              <div className="product-group-body-filter-text">
                <span>{t("products_filter_discount")}</span>
              </div>
              {[60, 30, 10].map((value, idx) => (
                <div
                  className="product-group-body-filter-discount-option product-group-body-filter-option"
                  key={idx}
                >
                  <input
                    type="radio"
                    value={value}
                    checked={discountOption === value}
                    onChange={(event) =>
                      setDiscountOption(parseInt(event.target.value))
                    }
                  />
                  <span>{value}% and up</span>
                </div>
              ))}
            </div>
            <div className="product-group-body-filter-rating group-filter">
              <div className="product-group-body-filter-text">
                <span>{t("products_filter_rating")}</span>
              </div>
              {[4, 3, 2, 1].map((rating, idx) => (
                <div
                  className="product-group-body-filter-rating-option product-group-body-filter-option"
                  key={idx}
                >
                  <input
                    type="radio"
                    value={rating}
                    checked={ratingOption === rating}
                    onChange={(event) =>
                      setRatingOption(parseInt(event.target.value))
                    }
                  />
                  <div className="product-group-body-filter-option-text">
                    {createRatingStars(rating)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {products.length === 0 && hasFilter ? (
            <div className="product-group-no-products">
              <span>
                No product has found under this filter, please change the filter
                and try again.
              </span>
            </div>
          ) : (
            <div className="product-group-body-list">
              {products.map((product, idx) => (
                <Link
                  key={idx}
                  className="product-group-body-grid-item"
                  to={`/product/${product.sku}`}
                  target="_blank"
                >
                  <div className="product-group-body-grid-item-image-container">
                    <AsyncImage
                      src={product.imageUrl}
                      style={{
                        width: "180px",
                        height: "180px",
                        borderRadius: 0,
                        backgroundColor: "#f5f5f5",
                      }}
                      Transition={Blur}
                      loader={<div style={{ background: "#888" }} />}
                    />
                  </div>
                  <div className="product-group-item-status-container">
                    <div className="product-group-item-discount">
                      <span>{product.discount}% OFF</span>
                    </div>
                    <div
                      className={toClassNames([
                        "product-group-item-status",
                        hotProductSkus.includes(product.sku)
                          ? "product-group-item-hot"
                          : limitedProductSkus.includes(product.sku)
                          ? "product-group-item-limited"
                          : "product-group-item-label-hidden",
                      ])}
                    >
                      <span>
                        {hotProductSkus.includes(product.sku)
                          ? "HOT!"
                          : limitedProductSkus.includes(product.sku)
                          ? "LIMITED"
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="product-group-item-product-name">
                    <span>{product.name}</span>
                  </div>
                  <div className="product-group-item-price-and-rating">
                    <div className="product-group-item-product-price">
                      ${(Math.round(product.price * 100) / 100).toFixed(2)}
                    </div>
                    <span className="product-group-item-list-price">
                      ${(Math.round(product.listPrice * 100) / 100).toFixed(2)}
                    </span>
                    <div className="product-group-item-rating">
                      <RatingStarIcon
                        width={20}
                        height={20}
                        className={"product-group-item-rating-icon"}
                      />
                      <span className="product-group-item-rating-number">
                        {product?.rating.toFixed(1)}
                      </span>
                      <span className="product-group-item-rating-count">
                        ({product?.ratingCount})
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className="product-group-row product-group-pagination">
          <Pagination
            className="product-group-pagination-bar"
            currentPage={currentPage}
            totalCount={
              totalResults === 0 ? 0 : totalResults > 9980 ? 9980 : totalResults
            }
            pageSize={20}
            siblingCount={0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export const ProductGroupPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileProductGroupPage /> : <DesktopProductGroupPage />;
};
