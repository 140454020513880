import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./imageGallery.scss";

const ImageGallery = ({ images }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [fadeTransition, setFadeTransition] = useState(false);
  const scrollRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageLenRef = useRef(null);
  const imageResultRef = useRef(null);

  const scrollToIndex = (index) => {
    scrollRef.current.scrollTop = index * 100; // Adjust this value based on your thumbnail height
  };

  useEffect(() => {
    scrollToIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  const handleArrowClick = (direction) => {
    setFadeTransition(true);
    setTimeout(() => {
      if (direction === "up") {
        if (selectedImageIndex > 0) {
          setSelectedImageIndex(selectedImageIndex - 1);
        } else {
          setSelectedImageIndex(images.length - 1);
        }
      } else if (direction === "down") {
        if (selectedImageIndex < images.length - 1) {
          setSelectedImageIndex(selectedImageIndex + 1);
        } else {
          setSelectedImageIndex(0);
        }
      }
      setFadeTransition(false);
    }, 300);
  };

  const getMousePosition = (event, imageContainerRect, imageLenRect) => {
    let x = event.clientX - imageContainerRect.left - imageLenRect.width / 2;
    let y = event.clientY - imageContainerRect.top - imageLenRect.width / 2;
    const minX = 0;
    const minY = 0;
    const maxX = imageContainerRect.width - imageLenRect.width;
    const maxY = imageContainerRect.height - imageLenRect.height;

    if (x <= minX) {
      x = minX;
    } else if (x >= maxX) {
      x = maxX;
    }

    if (y <= minY) {
      y = minY;
    } else if (y >= maxY) {
      y = maxY;
    }
    return { x, y };
  };

  const zoomImage = (event) => {
    const imageRect = imageRef?.current.getBoundingClientRect();
    const imageContainerRect =
      imageContainerRef?.current.getBoundingClientRect();
    const imageLenRect = imageLenRef?.current.getBoundingClientRect();
    const imageResultRect = imageResultRef?.current.getBoundingClientRect();

    const { x, y } = getMousePosition(event, imageContainerRect, imageLenRect);

    imageLenRef.current.style.left = x + "px";
    imageLenRef.current.style.top = y + "px";

    const fx = imageResultRect.width / imageLenRect.width;
    const fy = imageResultRect.height / imageLenRect.height;

    imageResultRef.current.style.backgroundImage = `url(${images[selectedImageIndex]})`;
    imageResultRef.current.style.backgroundSize = `${imageRect.width * fx}px ${
      imageRect.height * fy
    }px`;
    imageResultRef.current.style.backgroundPosition = `-${x * fx}px -${
      y * fy
    }px`;
  };

  return (
    <div className="image-gallery">
      <div className="thumbnail-container">
        <IoIosArrowUp
          className="arrow"
          onClick={() => handleArrowClick("up")}
        />
        <div className="thumbnails" ref={scrollRef}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${
                index === selectedImageIndex ? "selected" : ""
              }`}
              onClick={() => setSelectedImageIndex(index)}
            />
          ))}
        </div>
        <IoIosArrowDown
          className="arrow"
          onClick={() => handleArrowClick("down")}
        />
      </div>
      <div
        className="main-image-container"
        ref={imageContainerRef}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onMouseMove={zoomImage}
      >
        <img
          src={images[selectedImageIndex]}
          alt="Selected"
          className={`main-image ${fadeTransition ? "fade" : ""}`}
          ref={imageRef}
        />
        <div
          className={`main-image-len ${!hovered ? "hide" : ""}`}
          ref={imageLenRef}
        />
        <div
          className={`main-image-result ${!hovered ? "hide" : ""}`}
          ref={imageResultRef}
        />
      </div>
    </div>
  );
};

export default ImageGallery;
