import React, { useEffect } from "react";
import { useModal } from "react-modal-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./productDetailPage.scss";
import ImageGallery from "./ImageGallery";
import ProductTabs from "./ProductTabs";
import RecommendProductsTab from "./RecommendProductsTab";
import CouponCodeModal from "./CouponCodeModal";
import { useTranslation } from "react-i18next";
import { formatDistance } from "date-fns";
import {
  getProductBySku,
  getRecommendProductsByCategory,
} from "../../reducers/products/actions";
import {
  selectProductBySku,
  selectRecommendProducts,
} from "../../reducers/products/listingSelectors";
import { isEmpty, isNil } from "lodash";
import { mapLocaleToDateLocale } from "../../utils/locales";
import ChatBox from "../ChatBox";
import RatingStarIcon from "../../icons/RatingStarIcon";
import { CiHeart } from "react-icons/ci";
import { selectListingUser } from "../../reducers/users/listingSelectors";
import { addUserTracking } from "../../reducers/users/actions";
import { VIEW, PURCHASE } from "./options/userTrackingType";

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { sku } = useParams();
  const { id: userId } = useSelector(selectListingUser);
  const locale = i18n.language;

  const [showModal, hideModal] = useModal(() => (
    <CouponCodeModal onClose={hideModal} />
  ));

  useEffect(() => {
    dispatch(getProductBySku({ sku, navigate, locale }));
  }, [dispatch, sku, navigate, locale]);

  const product = useSelector(selectProductBySku);
  const recommendProducts = useSelector(selectRecommendProducts);

  useEffect(() => {
    const { category, sku } = product || {};
    if (category !== "" && sku !== "") {
      dispatch(
        getRecommendProductsByCategory({ category, sku, pageSize: 5, locale }),
      );
    }
  }, [dispatch, product, locale]);

  useEffect(() => {
    if (product && product.category && sku) {
      dispatch(
        addUserTracking({
          userId,
          sku,
          category: product.category,
          type: VIEW,
        }),
      );
    }
  }, [dispatch, product, sku, userId]);

  const handleProductLinkClick = () => {
    if (product && product.category && sku && userId) {
      dispatch(
        addUserTracking({
          userId,
          sku,
          category: product.category,
          type: PURCHASE,
        }),
      );
    }
    window.open(`${product.url}`, "_blank");
  };

  if (isEmpty(product)) {
    return null;
  }

  return (
    <div className="redesign-product-detail-page">
      <div className="column">
        <div className="row product-description-image-gallery">
          <ImageGallery images={product.imageUrls.split("\n")} />
        </div>
        <div className="product-favorite-button-container">
          <CiHeart size={40} />
        </div>
        <div className="row product-description-tabs">
          <ProductTabs
            descriptions={product.description.split("\n")}
            productReviews={product.productReviews}
          />
        </div>
        <div className="row product-description-chat">
          <ChatBox sku={product.sku} />
        </div>
      </div>
      <div className="column product-description-right-column">
        <div className="product-description-top-right">
          {!product.expired && product.available && (
            <div className="row discount-status-row">
              <div className="discount-container">
                <span>{product.discount}% OFF</span>
              </div>
            </div>
          )}
          {!product.available && (
            <div className="row status-row">
              <div className="status-container">
                <span>Not Available</span>
              </div>
            </div>
          )}
          <div className="row product-title-container">{product.name}</div>
          <div className="row product-description-price">
            {!product.expired ? (
              <div className="product-price-container">
                <div className="column asking-price">
                  <span>
                    {" "}
                    ${(Math.round(product.price * 100) / 100).toFixed(2)}
                  </span>
                </div>
                <div className="column original-price">
                  <span>
                    ${(Math.round(product.listPrice * 100) / 100).toFixed(2)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="product-price-container">
                <div className="column asking-price">
                  <span>
                    {" "}
                    ${(Math.round(product.price * 100) / 100).toFixed(2)}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="row product-description-rating">
            <div className="product-rating-container">
              <div className="col product-rating-col">
                <div className="product-rating-icon">
                  <RatingStarIcon />
                  <span className="product-rating-text">
                    <b>{product.rating}</b>/5
                  </span>
                </div>
              </div>
              <div className="col product-rating-col">
                <div className="product-rating-icon">
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9382 5C13.9382 7.47401 11.8804 9.5 9.31653 9.5C6.75263 9.5 4.69482 7.47401 4.69482 5C4.69482 2.52599 6.75263 0.5 9.31653 0.5C11.8804 0.5 13.9382 2.52599 13.9382 5Z"
                      fill="#FFDD29"
                      stroke="black"
                    />
                    <path
                      d="M17.5231 19H0.597168V14.235C2.04015 13.0731 5.54521 11 9.31624 11C13.0808 11 16.2662 13.068 17.5231 14.2157V19Z"
                      fill="#FFDD29"
                      stroke="black"
                    />
                  </svg>
                  <span className="product-rating-text">
                    <b>{product.ratingCount}</b> ratings
                  </span>
                </div>
              </div>
              <div className="col product-rating-col">
                <div className="product-rating-icon">
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.07275 5.96552H5.92489L11.3162 1L16.1683 5.96552H21.5596L20.8048 9.82759L20.1039 13.4138L19.4031 17H15.09H11.0466H7.00315H3.22926L2.5284 13.4138L1.82753 9.82759L1.07275 5.96552Z"
                      fill="#FFDD29"
                    />
                    <path
                      d="M5.92489 5.96552H1.07275L1.82753 9.82759M5.92489 5.96552L11.3162 1L16.1683 5.96552M5.92489 5.96552L7.00315 17M5.92489 5.96552H11.0466M16.1683 5.96552H21.5596L20.8048 9.82759M16.1683 5.96552L15.09 17M16.1683 5.96552H11.0466M7.00315 17H3.22926L2.5284 13.4138M7.00315 17H11.0466M15.09 17H19.4031L20.1039 13.4138M15.09 17H11.0466M11.0466 5.96552V17M1.82753 9.82759H20.8048M1.82753 9.82759L2.5284 13.4138M20.8048 9.82759L20.1039 13.4138M2.5284 13.4138H20.1039"
                      stroke="black"
                    />
                  </svg>
                  <span className="product-rating-text">
                    <b>100+</b> brought in past month
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row product-description-buttons">
            <div className="product-buttons-container">
              {!product.promoCodeExpired && (
                <div className="col">
                  <div
                    className="product-buttons-code product-button"
                    onClick={showModal}
                  >
                    <span>{t("product_detail_get_code")}</span>
                  </div>
                  <div className="product-button-subcontent product-button">
                    <span>{t("product_detail_coupon_code_instruction")}</span>
                  </div>
                </div>
              )}
              <div className="col">
                <div
                  className="product-buttons-link product-button"
                  onClick={handleProductLinkClick}
                >
                  <span>{t("product_detail_purchase_title")}</span>
                </div>
                {!product.promoCodeExpired && (
                  <div className="product-button-link-subcontent">
                    <span>
                      {!isNil(product.endDate)
                        ? t("product_detail_expired_time", {
                            expiredTime: formatDistance(
                              new Date(product.endDate),
                              new Date(),
                              {
                                locale: mapLocaleToDateLocale({
                                  i18nLocale: locale,
                                }),
                              },
                            ),
                          })
                        : t("product_detail_expired_soon")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row product-description-recommend-products">
          <RecommendProductsTab recommendProducts={recommendProducts} />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
