import React, { useEffect, useState } from "react";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import bannerImage1 from "../../images/banner1-01-01.png";
import trustImage from "../../images/trust-words.png";
import Navbar from "../header/NavBar";
import SecondaryNavBar from "../header/SecondaryNavBar";
import TrendingProducts from "./TrendingProducts";
import { useIsMobile } from "./hook/layoutHooks";
import "./mainPage.scss";
import ShopByCategories from "./ShopByCategories";
import { Footer } from "../footer/Footer";
import { MobileHeader } from "../header/MobileHeader";
import { CategoryOverlay } from "../header/CategoryOverlay";
import homeKitchenImage from "../../images/category-home-kitchen.png";
import kidsToysImage from "../../images/category-kid-toy.png";
import fashionBeautyImage from "../../images/category-fashion-beauty.png";
import healthSportsImage from "../../images/category-health-sports.png";
import techGadgetsImage from "../../images/categories-tech-gadgets.png";
import { IoMdArrowForward } from "react-icons/io";
import { MobileFooter } from "../footer/MobileFooter";
import { getTrendingProducts } from "../../reducers/products/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectTrendingProducts } from "../../reducers/products/listingSelectors";
import { MobileProductList } from "./mobile/MobileProductList";
import MobileLoginModalOverlay from "../header/MobileLoginModalOverlay";
import { ToastContainer } from "react-toastify";

const DesktopComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="App">
      <div className="navbar-container">
        <Navbar />
        <SecondaryNavBar />
      </div>
      <div className="main-page-container">
        <div className="row main-page-banner">
          <AsyncImage
            src={bannerImage1}
            style={{ width: "100%", height: "553px", borderRadius: 0 }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
          <Link
            to="/products"
            className="main-page-banner-shop-now-button"
            style={{ textDecoration: "none" }}
          >
            <span>{t("main_page_shop_now")}</span>
          </Link>
        </div>
        <div className="row row-2">
          <TrendingProducts />
        </div>
        <div className="row row-3">
          <Link
            to="/products"
            className="main-page-view-all-products"
            style={{ textDecoration: "none" }}
          >
            <span>{t("main_page_view_all_products")}</span>
          </Link>
        </div>
        <div className="row row-4">
          <div className="main-page-shop-by-category-title">
            <span>{t("menu_bar_shop_by_category")}</span>
          </div>
        </div>
        <div className="row row-5">
          <ShopByCategories />
        </div>
        <div className="row row-6">
          <AsyncImage
            src={trustImage}
            style={{ width: "100%", height: "250px", borderRadius: 0 }}
            Transition={Blur}
            loader={<div style={{ background: "#888" }} />}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

const MobileMainBody = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const locale = i18n.language;

  useEffect(() => {
    dispatch(
      getTrendingProducts({ pageNum: currentPage - 1, pageSize: 10, locale }),
    );
  }, [dispatch, currentPage, locale]);
  const products = useSelector(selectTrendingProducts);

  return (
    <div className="mobile-main-page-body">
      <div className="mobile-main-page-gallery">
        <img src={bannerImage1} className="gallery-image" alt="banner-1" />
      </div>
      <Link
        to="/products"
        className="main-page-banner-shop-now-button"
        style={{ textDecoration: "none" }}
      >
        <div className="main-page-banner-shop-now-button-text">
          {t("main_page_shop_now")}
        </div>
      </Link>
      <div className="mobile-main-page-trending-products">
        <div className="trending-product-row-products-title">
          <span>{t("main_page_trending_products_title")}</span>
        </div>
        <MobileProductList
          products={products}
          totalResults={100}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Link
        to="/products"
        className="mobile-main-page-view-all-products-button"
        style={{ textDecoration: "none" }}
      >
        <div className="main-page-banner-shop-now-button-text">
          {t("main_page_view_all_products")}
        </div>
      </Link>
      <div className="mobile-main-page-trust-banner">
        <img src={trustImage} className="trust-image" alt="trust-1" />
      </div>
      <div className="mobile-main-page-shop-by-categories">
        <span className="shop-by-category-title">
          {t("menu_bar_shop_by_category")}
        </span>
        <div className="shop-by-category-container">
          <Link
            className="shop-by-category-col"
            to="/products?topCategory=Home+%26+Kitchen&categories=Living+%26+Dining,Garden+%26+Outdoor,Home+Improvement,Appliances,Office"
          >
            <AsyncImage
              src={homeKitchenImage}
              style={{
                borderRadius: 0,
              }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
            <div className="shop-by-category-home-kitchen-text-container">
              <div className="shop-by-category-home-kitchen-text">
                {t("product_category_home_kitchen")}
              </div>
              <div className="col-home-kitchen-button-container">
                <IoMdArrowForward size={24} className="home-kitchen-button" />
              </div>
            </div>
          </Link>
          <Link
            className="shop-by-category-col"
            to="/products?topCategory=Kids+%26+Pets&categories=Toys+%26+Games,Pet+Supplies,Baby"
          >
            <AsyncImage
              src={kidsToysImage}
              style={{
                borderRadius: 0,
              }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
            <div className="shop-by-category-kids-toys-text-container">
              <div className="shop-by-category-kids-toys-text">
                {t("product_category_kids_pets")}
              </div>
              <div className="col-kids-and-toys-button-container">
                <IoMdArrowForward size={24} className="kids-and-toys-button" />
              </div>
            </div>
          </Link>
          <Link
            className="shop-by-category-col"
            to="/products?topCategory=Fashion+%26+Beauty&categories=Beauty,Clothing,Fashion,Handmade,Arts%2C+Crafts+%26+Sewing"
          >
            <AsyncImage
              src={fashionBeautyImage}
              style={{
                borderRadius: 0,
              }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
            <div className="shop-by-category-fashion-beauty-text-container">
              <div className="shop-by-category-fashion-beauty-text">
                {t("product_category_fashion_beauty")}
              </div>
              <div className="col-fashion-beauty-button-container">
                <IoMdArrowForward size={24} className="fashion-beauty-button" />
              </div>
            </div>
          </Link>
          <Link
            className="shop-by-category-col"
            to="/products?topCategory=Health+%26+Sport&categories=Sports+%26+Outdoors,Health+%26+Household,Grocery+%26+Gourmet+Food"
          >
            <AsyncImage
              src={healthSportsImage}
              style={{
                borderRadius: 0,
              }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
            <div className="shop-by-category-health-sports-text-container">
              <div className="shop-by-category-health-sports-text">
                {t("product_category_health_sports")}
              </div>
              <div className="col-health-sports-button-container">
                <IoMdArrowForward size={24} className="health-sports-button" />
              </div>
            </div>
          </Link>
          <Link
            className="shop-by-category-col"
            to="/products?topCategory=Tech+%26+Gadgets&categories=Electronics,Cell+Phones+%26+Accessories,Video+Games,Automotive,Industrial+%26+Scientific,Musical+Instruments"
          >
            <AsyncImage
              src={techGadgetsImage}
              style={{
                borderRadius: 0,
              }}
              Transition={Blur}
              loader={<div style={{ background: "#888" }} />}
            />
            <div className="shop-by-category-tech-gadgets-text-container">
              <div className="shop-by-category-tech-gadgets-text">
                {t("product_category_tech_gadgets")}
              </div>
              <div className="col-tech-gadgets-button-container">
                <IoMdArrowForward size={24} className="tech-gadgets-button" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const MobileComponent = () => {
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };

  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };

  // Toggle body class to disable or enable scrolling
  if (isCategoryOverlayOpen || isLoginModalOverlayOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className="App">
      <div className="mobile-main-page-container">
        <MobileHeader
          toggleCategoryOverlay={toggleCategoryOverlay}
          toggleLoginOverlay={toggleLoginModalOverlay}
        />
        <MobileMainBody />
        <ToastContainer />
        <MobileFooter />
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};

export const MainPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileComponent /> : <DesktopComponent />;
};
