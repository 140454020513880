import React, { useEffect, useMemo, useState } from "react";
import { MobileHeader } from "../../header/MobileHeader";
import { MobileProductList } from "./MobileProductList";
import { MobileFooter } from "../../footer/MobileFooter";
import { CategoryOverlay } from "../../header/CategoryOverlay";
import { useSearchParams } from "react-router-dom";
import { searchProductsByQuery } from "../../../reducers/products/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSearchProducts,
  selectSearchTotalResults,
} from "../../../reducers/products/listingSelectors";
import MobileLoginModalOverlay from '../../header/MobileLoginModalOverlay';

export const MobileSearchProductsPage = () => {
  const dispatch = useDispatch();
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const query = useMemo(() => {
    return searchParams.get("q") || [];
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(
      searchProductsByQuery({ query, pageNum: currentPage - 1, pageSize: 20 }),
    );
  }, [dispatch, currentPage, query]);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };
  
  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };
  
  const products = useSelector(selectSearchProducts);
  const totalResults = useSelector(selectSearchTotalResults);

  return (
    <div className="mobile-search-page">
      <div className="mobile-products-group-container">
        <MobileHeader toggleCategoryOverlay={toggleCategoryOverlay} />
        <MobileProductList
          products={products}
          totalResults={totalResults}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <MobileFooter />
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};
