import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

import * as api from "../api";
import RWDModal from "./RWDModal";
import LogoIcon from "../icons/LogoIcon";
import InputWithIcon from "./InputWithIcon";
import { Button, ButtonContainer } from "./ModalPopup";
import "./SignupModal.scss";
import PasswordIcon from "../icons/PasswordIcon";
import EyeIcon from "../icons/EyeIcon";
import CloseEyeIcon from "../icons/CloseEyeIcon";
import { selectLoginCredential } from "../reducers/users/createSelectors";
import { addUser, autoLoginUser } from "../reducers/users/actions";
import logoImage from "../images/savvyLogo.png";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,20}$/;
const numberRegex = /^[0-9\b]+$/;
const phoneFormatRegex = /^(\d{0,3})(\d{0,3})(\d{0,4})$/;

const initialState = {
  email: "",
  password: "",
  confirmedPassword: "",
  languageIsoCode: "en",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  referralCode: "",
};

const SignupModal = ({ onClose, showLoginModal, showWelcomeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [pwdVisible, setPwdVisible] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const { id, firstName, token } = useSelector(selectLoginCredential);
  const { email: emailText } = data;
  useEffect(() => {
    if (id !== undefined) {
      dispatch(autoLoginUser({ id, firstName, token }));
    }
  }, [dispatch, id, token, firstName]);

  useEffect(() => {
    // This effect will be triggered whenever inputValue changes.
    // It sets a timeout to execute an action after the user stops typing.

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    if (emailText.length > 0) {
      const timeoutId = setTimeout(async () => {
        if (!emailRegex.test(emailText)) {
          setErrors({ email: t("signup_modal_password_format_not_valid") });
        } else {
          await api
            .checkEmailExists(emailText)
            .then((res) => {
              const { valid } = res.data;
              if (!valid) {
                setErrors({
                  email: t("signup_modal_password_already_existed"),
                });
              } else {
                setErrors({ email: undefined });
              }
            })
            .catch((err) => {
              console.log({ err });
            });
        }
      }, 300);
      setTypingTimeout(timeoutId);
    }
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailText]);

  const togglePwd = () => {
    setPwdVisible((wasPwdVisible) => !wasPwdVisible);
  };

  useEffect(() => {
    const { password, confirmedPassword } = data;
    if (password.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: undefined,
      }));
      return;
    }
    if (!passwordRegex.test(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: t("signup_modal_password_not_strength"),
      }));
    } else if (password !== confirmedPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: t("signup_modal_password_not_same"),
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmedPassword: undefined,
      }));
    }
  }, [data, t]);

  const handlePhoneNumberChange = (event) => {
    const { id, value } = event.target;
    const newValue = value.replace(/\D/g, "");
    if (
      (newValue.length !== 0 && !numberRegex.test(newValue)) ||
      newValue.length > 10
    ) {
      return;
    }
    const match = newValue.match(phoneFormatRegex);
    if (match) {
      const formatNumber =
        (match[1] ? "(" + match[1] : "") +
        (match[2] ? `)-${match[2]}` : "") +
        (match[3] ? `-${match[3]}` : "");
      setData({ ...data, [id]: formatNumber });
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleOnChangeEmail = async (event) => {
    const { id, value } = event.target;
    if (value.length > 0 && !emailRegex.test(value)) {
      setErrors({ email: t("signup_modal_password_format_not_valid") });
    }
    setData({ ...data, [id]: value });
  };

  const handleSetField = (event) => {
    const { id, value } = event.target;
    if (id === "email1") {
      handleOnChangeEmail(event);
    } else if (id === "phoneNumber") {
      handlePhoneNumberChange(event);
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleShowLoginModal = (event) => {
    event.stopPropagation();
    onClose();
    showLoginModal();
  };

  const handleContinueStep = (event) => {
    event.stopPropagation();
    if (step === 2) {
      dispatch(addUser({ data, onClose, showWelcomeModal }));
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBackStep = (event) => {
    switch (step) {
      case 1: {
        handleShowLoginModal(event);
        break;
      }
      case 2: {
        setData(initialState);
        setStep((prevStep) => prevStep - 1);
        break;
      }
      default:
        break;
    }
  };
  const { email } = data || {};
  const submitButtonDisabled =
    step === 1
      ? email.length === 0 || errors.email !== undefined
      : Object.entries(data).some(([key, value]) => {
          if (key === "referralCode") {
            return false;
          } else if (key === "phoneNumber") {
            const newValue = value.replace(/\D/g, "");
            return newValue.length < 10;
          } else {
            return value.length === 0;
          }
        });

  return (
    <RWDModal
      onClose={onClose}
      LogoComponent={LogoIcon}
      logoImage={logoImage}
      header={t("signup_modal_header")}
      message={t("signup_modal_message")}
    >
      <MediaQuery minWidth={800}>
        {(matches) =>
          matches ? (
            <div>
              {step === 1 && (
                <div className="signup-container">
                  <InputWithIcon
                    containerClassName={
                      errors.email !== undefined ? "input-error" : ""
                    }
                    id="email"
                    type="text"
                    placeholder={t("modal_email_field_placeholder")}
                    value={data.email}
                    onChange={handleSetField}
                  />
                  {errors.email ? (
                    <span className="error-message">{errors.email}</span>
                  ) : null}
                  <div className="create-account-container">
                    <span className="not-have-account">
                      {t("signup_modal_have_account")}
                    </span>
                    <span
                      className="create-account"
                      onClick={handleShowLoginModal}
                    >
                      {t("common_signin")}
                    </span>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="signup-container">
                  <div className="info-title">
                    <span>{t("signup_modal_infomation_title")}</span>
                  </div>
                  <InputWithIcon
                    id="firstName"
                    type="text"
                    placeholder={t("modal_first_name_field_placeholder")}
                    value={data.firstName}
                    onChange={handleSetField}
                  />
                  <InputWithIcon
                    id="lastName"
                    type="text"
                    placeholder={t("modal_last_name_field_placeholder")}
                    value={data.lastName}
                    onChange={handleSetField}
                  />
                  <InputWithIcon
                    id="phoneNumber"
                    type="tel"
                    placeholder={t("modal_phone_number_field_placeholder")}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    value={data.phoneNumber}
                    onChange={handleSetField}
                  />
                  <div className="divider" />
                  <div className="info-title">
                    <span>{t("signup_modal_password_title")}</span>
                  </div>
                  <InputWithIcon
                    id="password"
                    type={!pwdVisible ? "password" : "text"}
                    placeholder={t("modal_password_field_placeholder")}
                    value={data.password}
                    icon={<PasswordIcon />}
                    icon2={
                      pwdVisible ? (
                        <EyeIcon onClick={togglePwd} />
                      ) : (
                        <CloseEyeIcon onClick={togglePwd} />
                      )
                    }
                    IconContainerClassName="create-icon"
                    onChange={handleSetField}
                  />
                  <div className="password-format">
                    {t("signup_modal_password_format")}
                  </div>
                  <InputWithIcon
                    id="confirmedPassword"
                    type={!pwdVisible ? "password" : "text"}
                    placeholder={t("modal_confirm_password_field_placeholder")}
                    value={data.confirmedPassword}
                    icon={<PasswordIcon />}
                    icon2={
                      pwdVisible ? (
                        <EyeIcon onClick={togglePwd} />
                      ) : (
                        <CloseEyeIcon onClick={togglePwd} />
                      )
                    }
                    IconContainerClassName="create-icon"
                    onChange={handleSetField}
                  />
                  {errors.confirmedPassword ? (
                    <span className="error-message">
                      {errors.confirmedPassword}
                    </span>
                  ) : null}
                  <div className="divider" />
                  <div className="info-title">
                    <span>{t("signup_modal_refer_code_title")}</span>
                  </div>
                  <InputWithIcon
                    id="referralCode"
                    type="text"
                    placeholder={t("modal_refer_code_field_placeholder")}
                    value={data.referralCode}
                    onChange={handleSetField}
                  />
                </div>
              )}
              <ButtonContainer>
                <Button onClick={handleBackStep} className="back-step-button">
                  <span>{t("common_back")}</span>
                </Button>
                <Button
                  disabled={submitButtonDisabled}
                  onClick={handleContinueStep}
                  className="continue-step-button"
                >
                  {step === 1 ? (
                    <span>{t("common_continue")}</span>
                  ) : (
                    <span>{t("common_submit")}</span>
                  )}
                </Button>
              </ButtonContainer>
            </div>
          ) : (
            <div className="mobile-signup-modal-container">
              {step === 1 && (
                <div className="mobile-signup-container">
                  <InputWithIcon
                    containerClassName={
                      errors.email !== undefined ? "input-error" : ""
                    }
                    id="email"
                    type="text"
                    placeholder={t("modal_email_field_placeholder")}
                    value={data.email}
                    onChange={handleSetField}
                  />
                  {errors.email ? (
                    <span className="mobile-error-message">{errors.email}</span>
                  ) : null}
                  <div>
                    <span className="mobile-not-have-account">
                      {t("signup_modal_have_account")}
                    </span>
                    <span
                      className="mobile-create-account"
                      onClick={handleShowLoginModal}
                    >
                      {t("common_signin")}
                    </span>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="mobile-signup-container">
                  <div className="mobile-info-title">
                    <span>{t("signup_modal_infomation_title")}</span>
                  </div>
                  <InputWithIcon
                    id="firstName"
                    type="text"
                    containerClassName="mobile-input-container"
                    placeholder={t("modal_first_name_field_placeholder")}
                    value={data.firstName}
                    onChange={handleSetField}
                  />
                  <InputWithIcon
                    id="lastName"
                    type="text"
                    containerClassName="mobile-input-container"
                    placeholder={t("modal_last_name_field_placeholder")}
                    value={data.lastName}
                    onChange={handleSetField}
                  />
                  <InputWithIcon
                    id="phoneNumber"
                    type="tel"
                    containerClassName="mobile-input-container"
                    placeholder={t("modal_phone_number_field_placeholder")}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    value={data.phoneNumber}
                    onChange={handleSetField}
                  />
                  <div className="mobile-divider" />
                  <div className="mobile-info-title">
                    <span>{t("signup_modal_password_title")}</span>
                  </div>
                  <InputWithIcon
                    id="password"
                    type={!pwdVisible ? "password" : "text"}
                    placeholder={t("modal_password_field_placeholder")}
                    value={data.password}
                    containerClassName="mobile-input-container"
                    icon={<PasswordIcon />}
                    icon2={
                      pwdVisible ? (
                        <EyeIcon onClick={togglePwd} />
                      ) : (
                        <CloseEyeIcon onClick={togglePwd} />
                      )
                    }
                    IconContainerClassName="create-icon"
                    onChange={handleSetField}
                  />
                  <div className="mobile-password-format">
                    {t("signup_modal_password_format")}
                  </div>
                  <InputWithIcon
                    id="confirmedPassword"
                    type={!pwdVisible ? "password" : "text"}
                    placeholder={t("modal_confirm_password_field_placeholder")}
                    containerClassName="mobile-input-container"
                    value={data.confirmedPassword}
                    icon={<PasswordIcon />}
                    icon2={
                      pwdVisible ? (
                        <EyeIcon onClick={togglePwd} />
                      ) : (
                        <CloseEyeIcon onClick={togglePwd} />
                      )
                    }
                    IconContainerClassName="create-icon"
                    onChange={handleSetField}
                  />
                  {errors.confirmedPassword ? (
                    <span className="mobile-error-message">
                      {errors.confirmedPassword}
                    </span>
                  ) : null}
                  <div className="mobile-divider" />
                  <div className="mobile-info-title">
                    <span>{t("signup_modal_refer_code_title")}</span>
                  </div>
                  <InputWithIcon
                    id="referralCode"
                    type="text"
                    placeholder={t("modal_refer_code_field_placeholder")}
                    containerClassName="mobile-input-container"
                    value={data.referralCode}
                    onChange={handleSetField}
                  />
                </div>
              )}
              <ButtonContainer>
                <Button onClick={handleBackStep} className="back-step-button">
                  <span className="mobile-form-btn">{t("common_back")}</span>
                </Button>
                <Button
                  disabled={submitButtonDisabled}
                  onClick={handleContinueStep}
                  className="mobile-continue-step-button"
                >
                  {step === 1 ? (
                    <span className="mobile-form-btn">
                      {t("common_continue")}
                    </span>
                  ) : (
                    <span className="mobile-form-btn">
                      {t("common_submit")}
                    </span>
                  )}
                </Button>
              </ButtonContainer>
            </div>
          )
        }
      </MediaQuery>
    </RWDModal>
  );
};

export default SignupModal;
