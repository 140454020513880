import { FaHome, FaLaptop, FaPaw } from "react-icons/fa";
import React from "react";
import { FaShirt, FaVolleyball } from "react-icons/fa6";

export const categories = {
  "Home & Kitchen": [
    "Appliances",
    "Garden & Outdoor",
    "Living & Dining",
    "Home Improvement",
    "Office",
  ],
  "Kids & Pets": ["Baby", "Pet Supplies", "Toys & Games"],
  "Fashion & Beauty": [
    "Beauty",
    "Clothing",
    "Fashion",
    "Art & Crafts",
    "Handmade",
  ],
  "Health & Sport": [
    "Health & Household",
    "Sports & Outdoors",
    "Grocery & Gourmet Food",
  ],
  "Tech & Gadgets": [
    "Cell Phones & Accessories",
    "Electronics",
    "Automotive",
    "Industrial & Scientific",
    "Musical Instruments",
  ],
};

export const categoryIconMapping = {
  "Home & Kitchen": <FaHome className="icon" size={20} />,
  "Kids & Pets": <FaPaw className="icon" size={20} />,
  "Fashion & Beauty": <FaShirt className="icon" size={20} />,
  "Health & Sport": <FaVolleyball className="icon" size={20} />,
  "Tech & Gadgets": <FaLaptop className="icon" size={20} />,
};
