import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import { resetProductsByCateogory } from "../../reducers/products/actions";
import "./languageDropdown.scss";
import { languaguesOptions } from "../../options/languages";

const LanguagesDropdown = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const convertLocale = () => {
    const language = i18n.language.split("-")[1];
    if (language === "VI") {
      return "VN";
    } else if (language === "AR") {
      return "SA";
    } else {
      return language;
    }
  };
  const currentLocale = convertLocale() || "US";

  const handleChangeLanguage = (value) => {
    const locale = languaguesOptions.find(value)?.code;
    i18n.changeLanguage(locale);
    dispatch(resetProductsByCateogory());
  };

  const customLabelOptions = {
    CN: "CN",
    FR: "FR",
    JP: "JP",
    KR: "KR",
    MX: "MX",
    PH: "PH",
    SA: "AR",
    RU: "RU",
    US: "US",
    VN: "VI",
  };

  return (
    <ReactFlagsSelect
      selected={currentLocale}
      className="language-dropdown"
      customLabels={customLabelOptions}
      countries={["CN", "FR", "JP", "KR", "MX", "PH", "SA", "RU", "US", "VN"]}
      onSelect={handleChangeLanguage}
    />
  );
};

export default LanguagesDropdown;
