import { createSelector } from "@reduxjs/toolkit";

export const activeProductCategorySelector = (state) =>
  state.products.listing.category;

export const selectActiveProductCategory = createSelector(
  activeProductCategorySelector,
  (category) => category,
);

export const selectSearchQuery = (state) => state.products.listing.query;

export const selectSearchProducts = (state) =>
  state.products.listing.searchProducts;

export const selectSearchTotalResults = (state) =>
  state.products.listing.searchTotalResults;

const productBySkuSelector = (state) => state.products.listing.product;

export const selectProductBySku = createSelector(
  productBySkuSelector,
  (product) => product,
);

export const selectTotalProductsCount = (state) =>
  state.products.listing.totalResults;

export const selectRecommendProducts = (state) =>
  state.products.listing.recommendProducts;

export const selectTrendingProducts = (state) =>
  state.products.listing.trendingProducts;

export const selectProducts = (state) => state.products.listing.products;

export const selectProductsByCategory = (state) =>
  state.products.listing.productsByCategory;

export const loadProductParamsSelector = (state) => {
  const { hasMore, pageNum } = state.products.listing.load;
  return { hasMore, pageNum };
};

export const searchProductParamsSelector = (state) => {
  const { hasMore, pageNum } = state.products.listing.search;
  return { hasMore, pageNum };
};

export const selectLoadProductParams = createSelector(
  loadProductParamsSelector,
  (params) => params,
);

export const selectSearchProductParams = createSelector(
  searchProductParamsSelector,
  (params) => params,
);

export const selectCutOffDateTime = (state) =>
  state.products.listing.cutOffDate;
