import React, { useState } from "react";
import ReactModal from "react-modal";
import { FiExternalLink, FiCopy } from "react-icons/fi";
import { RiCloseFill } from "react-icons/ri";
import "./couponCodeModal.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProductBySku } from "../../reducers/products/listingSelectors";
import copy from "copy-to-clipboard";
import { CouponCodeEmoji } from "../../icons/CouponCodeEmoji";

const CouponCodeModal = ({ onClose }) => {
  const { t } = useTranslation();
  const [copyCode, setCopyCode] = useState("product_detail_coupon_code_copy");
  const { couponCode, price, listPrice, url } = useSelector(selectProductBySku);

  const handleCopyCode = () => {
    copy(couponCode);
    setCopyCode("product_detail_coupon_code_copied");
  };
  return (
    <ReactModal
      isOpen
      onRequestClose={onClose}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(217, 217, 217, 0.75)",
        },
      }}
      className="coupon-code-modal"
    >
      <div className="coupon-code-container">
        <div className="coupon-code-row coupon-code-title">
          <div className="coupon-code-col">
            <CouponCodeEmoji width={60} height={60} />
          </div>
          <div className="coupon-code-col coupon-code-text-container">
            <div className="coupon-code-row coupon-code-text">
              <span>You have saved ${(listPrice - price).toFixed(2)}</span>
            </div>
            <div className="coupon-code-row coupon-code-soon-text">
              <span>{t("product_detail_coupon_code_saving_content")}</span>
            </div>
          </div>
        </div>
        <div className="coupon-code-row coupon-code-bottom">
          <div className="coupon-code-row coupon-code-copy-container">
            <div className="coupon-code-copy-text">
              <span>{couponCode}</span>
            </div>
            <div
              className="coupon-code-copy-button"
              onClick={() => handleCopyCode()}
            >
              <FiCopy className="coupon-code-icon" size={30} />
              <span className="coupon-code-link-text">{t(copyCode)}</span>
            </div>
          </div>
          <div
            className="coupon-code-row coupon-code-link-container"
            onClick={() => window.open(`${url}`, "_blank")}
          >
            <FiExternalLink className="coupon-code-icon" size={30} />
            <span className="coupon-code-link-text">
              {t("product_detail_coupon_code_link")}
            </span>
          </div>
        </div>
        <div className="coupon-code-close-button-container" onClick={onClose}>
          <RiCloseFill size={24} className="coupon-code-close-button" />
        </div>
      </div>
    </ReactModal>
  );
};

export default CouponCodeModal;
