import React, { useEffect, useRef, useState } from "react";
import "./mobileImageGallery.scss";

const MobileImageGallery = ({ images = [] }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  // eslint-disable-next-line
  const [fadeTransition, setFadeTransition] = useState(false);
  const scrollRef = useRef(null);
  const imageRef = useRef(null);
  const imageContainerRef = useRef(null);
  const imageLenRef = useRef(null);
  const imageResultRef = useRef(null);

  const scrollToIndex = (index) => {
    scrollRef.current.scrollTop = index * 100; // Adjust this value based on your thumbnail height
  };

  useEffect(() => {
    scrollToIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  return (
    <div className="mobile-image-gallery">
      <div
        className="main-image-container"
        ref={imageContainerRef}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img
          src={images[selectedImageIndex]}
          alt="Selected"
          className={`main-image ${fadeTransition ? "fade" : ""}`}
          ref={imageRef}
        />
        <div
          className={`main-image-len ${!hovered ? "hide" : ""}`}
          ref={imageLenRef}
        />
        <div
          className={`main-image-result ${!hovered ? "hide" : ""}`}
          ref={imageResultRef}
        />
      </div>
      <div className="thumbnail-container">
        <div className="thumbnails" ref={scrollRef}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${
                index === selectedImageIndex ? "selected" : ""
              }`}
              onClick={() => setSelectedImageIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileImageGallery;
